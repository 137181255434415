@use 'sass:math';

.homepage__usps--block-wrapper {
    @include breakpoint(small only) {
        h2 {
            padding: 0 4rem;
        }
    }

    .homepage__usps--block {
        @include breakpoint(small only) {
            padding: 0 0 0 4rem;

            .blaze-track-container {
                overflow: initial;
            }

            .blaze-pagination {
                display: flex;
                justify-content: center;
                padding-right: 4rem;
                margin: 6rem 0 3rem;
            }
        }

        @include breakpoint(medium) {
            .blaze-slider {
                height: 100%;

                .blaze-container {
                    height: 100%;

                    .blaze-track-container {
                        height: 100%;

                        .blaze-track {
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;
                            gap: 4rem;

                            > * {
                                width: auto;
                            }
                        }
                    }
                }

            }
        }

        .--image-holder {
            img:not(.--active) {
                display: none;
            }
        }

        .--usp {
            cursor: pointer;

            img {
                width: 100%;
            }

            .--title {
                display: flex;
                font-weight: 500;
                margin: 2.8rem 0 .8rem;
                color: color(CustomGray, Gray-400);
                align-items: center;
                gap: 1.2rem;
                font-size: 2.4rem;
                line-height: 2.6rem;
                position: relative;

                @include breakpoint(medium) {
                    font-size: 3.2rem;
                    line-height: 3.4rem;
                    margin: 0 0 .8rem;
                }

                &::before {
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background: color(CustomGray, Gray-200);
                    width: 1rem;
                    height: 1rem;
                    flex: 0 0 1rem;

                    @include breakpoint(small only) {
                        position: absolute;
                        left: -2rem;
                    }

                    @include breakpoint(medium) {
                        width: 1.3rem;
                        height: 1.3rem;
                        flex: 0 0 1.3rem;
                    }
                }
            }

            p {
                color: color(CustomGray, Gray-400);
                font-size: 1.8rem;
                line-height: 2.2rem;

                @include breakpoint(medium) {
                    max-width: 85%;
                    padding-left: 3rem;
                }
            }

            &.--active {
                cursor: default;

                .--title {
                    color: color(base, Anthracite);

                    &::before {
                        background: color(base, Green);
                    }
                }
            }
        }
    }
}
