@use 'sass:math';

footer.footer {
    border-top: .1rem solid color(CustomGray, Gray-200);
    padding: 6rem 0 0;
    margin: 4rem 0 0;

    @include breakpoint(medium) {
        padding: 8rem 0 0;
    }

    .footer__row {
        .footer__links {
            @include breakpoint(small only) {
                margin-bottom: 2rem;
            }

            h5 {
                font-size: 1.4rem;
                line-height: 2.2rem;
                color: color(CustomGray, Gray-400);
                margin: 0 0 2rem;
            }

            ul {
                li {
                    a {
                        font-weight: 500;
                        font-size: 1.6rem;
                        line-height: 3rem;
                        display: inline-flex;
                        align-items: center;

                        &::before {
                            @include transition;
                            content: '';
                            width: 0;
                            height: 0;
                            background: color(CustomGray, Gray-300);
                            border-radius: 50%;
                        }

                        @include hover {
                            &::before {
                                width: .4rem;
                                height: .4rem;
                                margin-right: .5rem;
                            }
                        }
                    }
                }
            }

            &.--two-colum ul {
                columns: 2;

                @include breakpoint(medium) {
                    column-gap: 4rem;
                }
            }

            .--socials {
                margin: 0 0 6rem;
                display: flex;
                align-items: center;
                gap: 1.5rem;
            }
        }

        .--logo {
            display: inline-flex;
            margin: 3rem 0 0;

            @include breakpoint(medium) {
                margin: 6rem 0 0;
            }
        }
    }

    .absolute__footer {
        border-top: .1rem solid color(CustomGray, Gray-200);
        padding: 3rem 0;
        margin: 3rem 0 0;

        @include breakpoint(medium) {
            margin: 6rem 0 0;
        }

        .grid-container {
            display: flex;

            @include breakpoint(small only) {
                flex-wrap: wrap;
            }

            .--content {
                display: flex;
                align-items: center;
                gap: 1rem;

                @include breakpoint(small only) {
                    padding-bottom: 3rem;
                    flex-wrap: wrap;
                    gap: 0;
                    position: relative;

                    &::after {
                        position: absolute;
                        bottom: 0;
                        content: '';
                        height: .1rem;
                        background: color(CustomGray, Gray-200);
                        left: rem-calc(- math.div($grid__gutter, 2));
                        right: rem-calc(- math.div($grid__gutter, 2));
                    }

                    > span {
                        flex-basis: 100%;
                    }
                }

                > p {
                    display: flex;
                    gap: 1rem;
                }

                > span, > a, > p > span, > p > a {
                    color: color(CustomGray, Gray-400);
                    font-size: 1.4rem;
                    line-height: 2.2rem;

                    &:not(:last-child)::after {
                        content: '•';
                        display: inline-block;
                        margin: 0 1rem;

                        @include breakpoint(medium) {
                            margin: 0 0 0 1rem;
                        }
                    }

                    @include breakpoint(small only) {
                        &:not(:last-child):is(span)::after {
                            display: none;
                        }
                    }

                    &:is(a) {
                        @include hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .footer__rating {
                @include rating;
                display: flex;
                align-items: center;
                gap: 1.5rem;
                justify-content: center;
                width: 100%;
                padding-top: 3rem;

                @include breakpoint(medium) {
                    justify-content: normal;
                    margin-left: auto;
                    width: auto;
                    padding: 0;
                }

                span {
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    color: color(CustomGray, Gray-400);

                    > span {
                        color: color(base, Anthracite);
                    }
                }
            }
        }
    }
}
