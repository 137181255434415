.product__view {
    .product__header {
        border-bottom: .1rem solid color(CustomGray, Gray-200);
        padding: 1.5rem 0 3rem;

        @include breakpoint(medium) {
            padding: 1rem 0 4rem 4rem;

            &.--small {
                padding: 1rem 0 3rem 2rem;
            }
        }

        > a:not(.--button) {
            display: inline-flex;
            gap: .8rem;
            align-items: center;
            text-transform: capitalize;
            margin: 0 0 2rem;

            &::before {
                content: '';
                width: .4rem;
                height: .8rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.061' height='8.707' viewBox='0 0 5.061 8.707'%3E%3Cpath id='Path_3278' data-name='Path 3278' d='M8,0,4,4,0,0' transform='translate(4.707 0.354) rotate(90)' fill='none' stroke='%23212121' stroke-miterlimit='10' stroke-width='1'/%3E%3C/svg%3E%0A");
            }

            @include hover() {
                text-decoration: underline;
            }
        }

        h1 {
            font-size: clamp(3.2rem, 1.067rem + 2.778vw, 4.4rem);
            line-height: clamp(3.4rem, 1.067rem + 2.778vw, 4.6rem);
            font-weight: 600;
            margin: 0 0 2rem;
        }

        .product-description {
            p {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }

        .grouped__cta {
            margin: 3rem 0 0;
        }

        .grouped__products-container {
            margin: 3rem 0 0;

            > span {
                font-weight: 500;
                font-size: 1.6rem;
                display: block;
                margin: 0 0 1.5rem;
            }

            .grouped__products {
                div {
                    display: flex;
                    align-items: center;
                    gap: 3rem;

                    &:not(:last-child) {
                        margin: 0 0 1.5rem;
                    }

                    a {
                        gap: 1.5rem;
                        flex: 1;
                        justify-content: flex-start;
                        padding: 1.25rem 1.85rem;

                        &.--active {
                            pointer-events: none;
                            border-color: color(base, Green);
                            box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);
                        }

                        img {
                            width: 10rem;
                        }
                    }

                    > span {
                        font-size: 1.3rem;
                        color: color(Gray, Gray-600);
                        display: flex;
                        gap: .5rem;
                        align-items: baseline;
                        flex: 0 0 30%;

                        > span {
                            font-size: 1.7rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        &.product__totals {
            padding: 2rem 0 4rem 4rem;
            &.--sticky {
                position: sticky;
                top: 64px;
                background-color: white;
                display: block;
                z-index: 3;

                .--actions {
                    button {
                        background-color: color(base, DarkGreen);
                        color: color(base, White);
                    }
                }
            }
            .--totals {
                display: flex;
                align-items: center;
                margin: 0 0 2rem;

                span {
                    display: flex;
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2rem;

                    &.--price {
                        margin-left: auto;
                        gap: .5rem;
                        align-items: flex-end;

                        small {
                            line-height: normal;
                            font-size: 1.4rem;

                            @include breakpoint(medium) {
                                font-size: 1.6rem;
                                line-height: 2.2rem;
                            }
                        }
                    }
                }
            }

            .product__save {
                @include breakpoint(small only) {
                    margin: 1.5rem 0 0;
                }

                button {
                    @include default-button();
                    font-size: 1.4rem;
                    margin: 0;
                    padding: 1.2rem 1.6rem;
                    width: 100%;


                    &.--success {
                        background-color: color(alert, Success);
                        text-decoration: none;
                    }

                    &:disabled {
                        cursor: not-allowed;
                    }
                }

                > span {
                    display: block;
                    font-size: 1.1rem;
                    margin: .5rem 0 0;
                    color: color(alert, Error);
                }
            }
        }

        .--support-content {
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content: flex-end;

            p {
                font-size: 1.4rem;
                line-height: 2.2rem;
            }

            a {
                @include default-button();
                margin: 2rem 0 0;
                padding: 1.2rem 1.6rem;
                font-size: 1.4rem;
                background-color: color(base, White);
                color: color(base, Anthracite);
                border-color: color(base, Anthracite);

                &::before {
                    border-color: color(base, White);
                }

                @include hover() {
                    background-color: color(base, Anthracite);
                    color: color(base, White);
                    border-color: color(base, Green);
                }
            }
        }

        .--configure-cta {
            margin: 2rem 0 0;
            padding: 0 1rem;
        }
    }
    .product__disclaimer p {
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
    .pdp_content__container {
        padding: 3rem 2rem;
    }

    .product-specifications {
        @include breakpoint(medium) {
            max-width: 50%;
        }

        dt {
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 2.4rem;
            margin: 0 0 1rem;

            @include breakpoint(small only) {
                padding: 1rem 0;
            }
        }

        dd {
            font-size: 1.5rem;
            line-height: 2.2rem;
            margin: 0 0 2.5rem;

            > div {
                display: flex;
                gap: 1rem;
                padding: .5rem;

                &:nth-child(even) {
                    background: #f9f9f9;
                }

                > span {
                    display: block;

                    &:first-child {
                        flex: 0 0 25%;
                    }
                }
            }
        }
    }

    .catalog__category-list {
        margin: 4rem 0;
        border-bottom: .1rem solid color(CustomGray, Gray-200);

        .header__container {
            margin: 0 0 4rem;

            h2, h3 {
                font-size: 3rem;
            }

            p {
                margin: 1rem 0 0;
            }
        }

        .product__tile a .image__holder > span {
            background: rgba(255, 255, 255, .6);
            bottom: auto;
            top: 2rem;
            right: auto;
            z-index: 1;
            height: auto;
            opacity: 1;
            font-weight: 500;
            line-height: normal;
            padding: 1rem 2rem;
            color: color(Gray, Gray-600);
        }
    }

    .appointment__holder {
        width: 100%;

        .appointment-container {
            position: relative;
            margin: 2rem 0 4rem;

            img {
                max-height: 35rem;
                object-fit: cover;
                width: 100%;
                @include breakpoint(small only) {
                    height: 50rem;
                }
            }

            .--content {
                bottom: 0;
                display: flex;
                left: 0;
                position: absolute;
                right: 0;
                align-items: center;
                top: 0;

                .inner-content {
                    @include breakpoint(medium) {
                        max-width: 45%;
                        padding: 3rem 0 3rem 3rem;
                    }

                    p {
                        margin: 1rem 0 2rem;
                    }
                }
            }
        }
    }
}
