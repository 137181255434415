.homepage__testimonials--block-wrapper {
    .homepage__testimonials--block {
        img {
            @include transition;
            display: none;

            &.--active {
                display: block;
            }

            @include breakpoint(small only) {
                margin: 0 0 2rem;
            }
        }

        .blaze-slider {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .--content {
                blockquote {
                    font-weight: 500;
                    font-size: 2.4rem;
                    line-height: 2.6rem;

                    @include breakpoint(medium) {
                        font-size: 3.2rem;
                        line-height: 3.4rem;
                    }
                }

                cite {
                    display: block;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    color: color(CustomGray, Gray-400);
                    margin: 1rem 0 0;

                }
            }

            .blaze-pagination {
                margin: 6rem 0 0;

                @include breakpoint(small only) {
                    margin: 4rem 0 0;
                    justify-content: center;
                }
            }
        }
    }
}
