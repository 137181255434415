.search__modal--overlay {
    transition: opacity .1s ease-in;
    opacity: 0;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .45);
    z-index: 10;
    flex-direction: column;
    align-items: center;

    .search__modal {
        display: flex;

        @include breakpoint(medium) {
            min-width: 45rem;
            margin: 5rem;
        }

        @include breakpoint(small only) {
            width: 100%;
            padding: 5rem 2rem;
        }

        > button {
            width: 4.6rem;
            height: 4.6rem;
            padding: 0;
            border-radius: .4rem 0 0 .4rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18' /%3E%3C/svg%3E%0A");
            background-size: 2.5rem;
            background-repeat: no-repeat;
            background-position: center;
        }

        .search-form__holder {
            flex: 1;

            form {
                position: relative;

                input {
                    padding-right: 6.5rem;
                    border-radius: 0 .4rem .4rem 0;
                }

                button {
                    @include transition;
                    width: 4.6rem;
                    height: 4.6rem;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1234' data-name='Group 1234' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_166' data-name='Rectangle 166' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_10' data-name='Ellipse 10' cx='7.5' cy='7.5' r='7.5' transform='translate(3 3)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_79' data-name='Line 79' x2='5.197' y2='5.197' transform='translate(15.803 15.803)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }
}
