.search__autocomplete {

    form {
        position: relative;

        .--loading {
            .--spinner {
                width: 4rem;
            }
        }

        .--remove {
            @include transition();
            position: absolute;
            right: 4.5rem;
            top: 1.3rem;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(CustomGray, Gray-400)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");

            @include hover() {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
            }
        }
    }

    .search__autocomplete_dropdown {
        @include border-radius();
        background: color(base, White);
        box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px,
        rgba(0, 0, 0, 0.07) 0 2px 4px,
        rgba(0, 0, 0, 0.07) 0 4px 8px,
        rgba(0, 0, 0, 0.07) 0 8px 16px,
        rgba(0, 0, 0, 0.07) 0 16px 32px,
        rgba(0, 0, 0, 0.07) 0 32px 64px;
        margin: 1rem 0 0;
        display: flex;
        flex-flow: column;
        overflow: hidden;

        > a {
            @include transition();
            display: flex;
            gap: 2rem;
            padding: 1rem;

            .image__holder {
                display: block;
                max-width: 7rem;

                img {
                    aspect-ratio: 16/11;
                    width: 100%;
                }
            }

            .--info {
                flex: 1;
                padding: 1rem 0;

                .--title {
                    display: block;
                    font-weight: 500;
                    font-size: 1.6rem;
                }

                .price__holder {
                    display: block;
                    font-size: 1.3rem;
                    color: color(CustomGray, Gray-400);
                }
            }

            &:not(:last-child) {
                border-bottom: .1rem solid color(CustomGray, Gray-200);
            }

            @include hover() {
                background: color(CustomGray, Gray-150);
            }
        }

        .no__results {
            padding: .5rem;
            text-align: center;
            font-size: 1.4rem;
            color: color(CustomGray, Gray-400);
        }
    }
}
