.physical-store {
    .breadcrumbs {
        padding: 1.5rem 0;
    }

    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }

    .store-locator__container {
        width: 100%;
        height: 50vh;
        min-height: 42rem;
        display: flex;
        flex-direction: column;

        @include breakpoint(small only) {
            height: 80vh;
        }

        .--container {
            position: relative;
            flex: 1;

            @include breakpoint(small only) {
                display: flex;
                flex-direction: column;
            }

            .search__wrapper {
                @include breakpoint(medium) {
                    position: relative;
                    height: 0;
                }

                .--search {
                    @include breakpoint(medium) {
                        @include border-radius();
                        position: absolute;
                        z-index: 1;
                        top: 2rem;
                        bottom: 2rem;
                        left: 2rem;
                        display: flex;
                        gap: 1rem;
                        width: fit-content;
                        height: fit-content;
                    }

                    .search__container {
                        background: color(base, White);
                        height: 100%;
                        width: fit-content;
                        padding: 2rem;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;
                        position: relative;

                        @include breakpoint(medium) {
                            @include border-radius();
                            max-width: 38rem;
                            box-shadow: rgba(99, 99, 99, 0.2) 0 .2rem .8rem 0;
                        }

                        h3 {
                            font-size: clamp(1.6rem, 1.029rem + 1.143vw, 2.6rem);
                        }

                        form {
                            > button {
                                height: 4.6rem;
                                font-size: 1.2rem;
                                padding: .5rem 2rem .5rem 3rem;
                                display: flex;
                                align-items: center;
                                background-repeat: no-repeat;
                                background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1328' data-name='Group 1328' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_204' data-name='Rectangle 204' width='24' height='24' fill='none'/%3E%3Cpath id='Path_3294' data-name='Path 3294' d='M46.2,57.571a.75.75,0,0,0,1.426-.025l2.208-7.177a.75.75,0,0,1,.5-.5l7.177-2.2a.75.75,0,0,0,.025-1.426l-16.5-6.12a.75.75,0,0,0-.952.949Z' transform='translate(-36.29 -36.326)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                                background-position: left center;
                                background-size: 2rem;
                            }

                            > div {
                                display: flex;
                                gap: 1rem;

                                @include breakpoint(small only) {
                                    flex-direction: column;
                                    flex: 1;
                                }

                                > span {
                                    flex: 1;

                                    .field__holder.--placeholder {
                                        > label {
                                            padding: .5rem 0 0 .5rem;
                                        }

                                        > input {
                                            padding: 1.8rem 1rem .2rem;
                                        }
                                    }
                                }

                                .--error {
                                    display: block;
                                    font-size: 1.2rem;
                                    margin: .5rem 0 0;
                                    color: color(alert, Error);
                                }

                                > button {
                                    height: 4.6rem;
                                }
                            }
                        }

                        > button {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
                            border-radius: 0 .4rem .4rem 0;
                            width: 2.5rem;
                            min-height: 8.6rem;
                            background-repeat: no-repeat;
                            background-position: center;
                            padding: 0;

                            &.--closed {
                                @include border-radius;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                            }
                        }

                        .nearest-store__container {
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;

                            > button {
                                font-size: 1.4rem;
                                cursor: pointer;

                                &.--active {
                                    font-weight: 700;
                                }
                            }
                        }

                        .--info {
                            p {
                                text-align: justify;
                                margin: 2rem 0;

                                @include breakpoint(small only) {
                                    font-size: 1.4rem;
                                    line-height: 2rem;
                                    margin: 0 0 1rem 0;
                                }
                            }

                            .--button {
                                width: 100%;
                            }
                        }
                    }

                    .active__store {
                        @include border-radius();
                        background: color(base, White);
                        display: flex;
                        gap: 2rem;
                        position: relative;
                        padding: 1rem 2rem;
                        border-top: .1rem solid color(Gray, Gray-200);

                        @include breakpoint(medium) {
                            box-shadow: rgba(99, 99, 99, 0.2) 0 .2rem .8rem 0;
                            margin: 2rem 0;
                            padding: 3rem;
                            width: 44rem;
                            border-top: none;
                        }

                        > button {
                            width: 2.8rem;
                            height: 2.8rem;
                            position: absolute;
                            top: 1rem;
                            right: 1rem;
                            padding: 0;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                            border-radius: 50%;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 1.6rem;

                            @include breakpoint(medium) {
                                top: -1rem;
                                right: -1rem;
                            }
                        }

                        .store__info {
                            flex: 1;

                            h4 {
                                font-size: 1.8rem;
                                font-weight: 700;
                            }

                            address {
                                margin: 1rem 0;

                                p {
                                    font-size: 1.4rem;
                                    line-height: 1.7;
                                }
                            }

                            > a:not(.--button) {
                                font-size: 1.4rem;
                                line-height: 1.4;
                                text-decoration: underline;
                                word-break: break-all;
                                display: block;
                                position: relative;
                                background-repeat: no-repeat;
                                background-position: left center;
                                background-size: 1.4rem;
                                padding-left: 2.2rem;
                                margin: .5rem 0;

                                &.--email {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                                }

                                &.--tel {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z' /%3E%3C/svg%3E%0A");
                                }
                            }

                            .--button {
                                margin-top: 1rem;
                            }
                        }

                        .store__opening-hours {
                            flex: 1;

                            > span {
                                font-size: 1.4rem;
                                font-weight: 700;
                            }

                            ul {
                                margin-top: 1rem;

                                li {
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: 1.2rem;
                                    line-height: 1.7;
                                }
                            }

                            .opening-hours__period-container {
                                text-align: right;
                            }

                        }
                    }
                }
            }
        }

        .vue-map-container {
            width: 100%;
            height: 100%;
        }
    }

    .--header {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 3rem;

        @include breakpoint(small only) {
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
            margin: 3rem 0;
        }

        h1 {
            font-size: 2rem;
            line-height: 1.4;
            max-width: 65rem;
            font-weight: 700;

            @include breakpoint(medium) {
                font-size: 3.4rem;
                line-height: 1.4;
            }
        }

        form {
            display: flex;
            gap: 1rem;
            align-items: center;

            > span {
                font-size: 1.5rem;
            }

            .country__select {
                position: relative;
                width: fit-content;

                &.--open {
                    > span {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                    }

                    > div {
                        display: block;
                    }
                }

                > span {
                    font-size: 1.5rem;
                    padding-right: 2rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: right center;
                    background-size: 1.4rem;
                    display: block;
                    cursor: pointer;

                    @include hover() {
                        text-decoration: underline;
                    }
                }

                > div {
                    position: absolute;
                    background: color(base, White);
                    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
                    border-radius: .5rem;
                    top: calc(100% + .2rem);
                    right: .5rem;
                    padding: 1rem;
                    border: .1rem solid color(Gray, Gray-250);
                    min-width: 18rem;
                    display: none;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: .8rem solid transparent;
                        border-right: .8rem solid transparent;
                        border-bottom: .8rem solid color(base, White);
                        top: -.8rem;
                        right: 1.5rem;
                    }

                    ul li {
                        display: flex;
                        align-items: center;
                        gap: 2rem;
                        font-size: 1.2rem;
                        justify-content: space-between;
                        padding: .5rem 0;

                        label {
                            @include transition();
                            cursor: pointer;
                        }

                        input {
                            @include transition();
                            cursor: pointer;
                            width: 1.8rem;
                            height: 1.8rem;
                            border-radius: .3rem;

                            &:checked::after {
                                height: 1.2rem;
                                width: 1.2rem;
                                background-size: contain;
                            }
                        }

                        @include hover() {
                            label {
                                color: color(Gray, Gray-900);
                                text-decoration: underline;
                            }

                            input {
                                border-color: color(Gray, Gray-400);
                            }
                        }
                    }

                    > button {
                        width: 100%;
                        padding: .7rem 1rem;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    .letter__row {
        display: flex;
        gap: 1rem;

        @include breakpoint(small only) {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
        }

        a {
            @include transition();
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            border: .1rem solid color(Gray, Gray-300);
            border-radius: .5rem;
            aspect-ratio: 1;
            font-size: 1.5rem;
            font-weight: 700;

            &.--disabled {
                color: color(Gray, Gray-400);
                border-color: color(Gray, Gray-250);
                pointer-events: none;
            }

            @include hover() {
                background: color(Gray, Gray-100);
            }
        }
    }

    .store__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
        gap: 5rem;
        margin-top: 5rem;

        > div {
            strong {
                font-size: 2.8rem;
                font-weight: 700;
                margin-bottom: 1rem;
                border-bottom: .1rem solid color(Gray, Gray-200);
                display: block;
                padding: 1.5rem 0;
            }

            dl {
                dt {
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    gap: 1rem;
                    justify-content: space-between;
                    font-size: 1.6rem;
                    border-bottom: .1rem solid color(Gray, Gray-100);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 1.4rem;
                    padding: 1rem 3.5rem 1rem 1rem;
                    background-position: right 1rem center;

                    @include breakpoint(medium) {
                        padding: 1rem 2.5rem 1rem 0;
                        background-position: right center;
                    }
                }

                dd {
                    position: relative;
                    z-index: 1;
                    display: none;
                }

                @include breakpoint(medium) {
                    &.--active {
                        dd {
                            margin-right: -2rem;
                            border-radius: 0 0 .5rem .5rem;
                        }

                        &.--wide {
                            dd {
                                grid-template-columns: repeat(2, 1fr);
                                border-radius: 0 .5rem .5rem .5rem;
                                margin-right: -100%;
                            }

                            &.--right {
                                dd {
                                    margin-right: -2rem;
                                    margin-left: -100%;
                                    border-radius: .5rem 0 .5rem .5rem;
                                }
                            }
                        }
                    }
                }

                &.--active {
                    dt {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                        position: relative;
                        background-color: color(Gray, Gray-100);

                        @include breakpoint(medium) {
                            &::before {
                                content: '';
                                position: absolute;
                                width: 2rem;
                                background: color(Gray, Gray-100);
                                right: 100%;
                                top: -.1rem;
                                bottom: -.1rem;
                                border-radius: .5rem 0 0 0;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                width: 2rem;
                                background: color(Gray, Gray-100);
                                left: 100%;
                                top: -.1rem;
                                bottom: -.1rem;
                                border-radius: 0 .5rem 0 0;
                            }
                        }
                    }

                    dd {
                        background-color: color(Gray, Gray-100);
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 1rem;
                        padding: 1rem;
                        border-bottom: .1rem solid color(Gray, Gray-100);

                        @include breakpoint(medium) {
                            margin-left: -2rem;
                        }

                        .--store {
                            background-color: color(base, White);
                            border-radius: .5rem;
                            padding: 1rem;

                            address {
                                font-size: 1.3rem;
                                line-height: 1.7;

                                a {
                                    font-size: 1.3rem;
                                    line-height: 1.7;
                                    text-decoration: underline;
                                }
                            }

                            .--actions {
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                margin: 1rem 0;

                                a {
                                    flex: 1;
                                }
                            }

                            > a {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.physical-store-detail {
    .header__image {
        height: 49.5rem;
        position: relative;
        margin-bottom: 4rem;

        @include breakpoint(medium) {
            margin-bottom: 8rem;
        }

        .--overlay {
            position: absolute;
            top: 4rem;
            bottom: 4rem;
            left: 0;
            width: 100%;
            z-index: 1;

            .grid-container {
                height: 100%;

                > div {
                    height: 100%;

                    > div {
                        height: 100%;
                    }
                }
            }

            .--content {
                background: rgba(255, 255, 255, 0.85);
                box-shadow: 0 .4rem .4rem 0 #00000040;
                height: 100%;
                padding: 2rem;

                @include breakpoint(medium) {
                    padding: 3rem 5rem;
                }

                h1 {
                    font-size: 3rem;
                    line-height: 4.8rem;
                    margin-bottom: 2rem;

                    @include breakpoint(medium) {
                        max-width: 60%;
                    }
                }

                p {
                    font-size: 2rem;
                    line-height: 3.2rem;
                    margin-bottom: 2rem;
                }
            }
        }

        picture {
            display: flex;
            height: 100%;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    }

    .store__content {
        margin: 2rem 0;

        @include breakpoint(medium) {
            margin: 4rem 0;
        }

        p, ul, ol, address, a {
            font-size: 1.8rem;
            line-height: 3rem;
            margin: 0 0 2rem;
        }

        h2, h3 {
            font-size: 2rem;
            line-height: 3rem;
            margin: 0 0 1rem;
            font-weight: 700;
        }

        .--media__holder {
            height: 36rem;
            overflow: hidden;

            > div {
                height: 36rem;
            }
        }

        > img {
            margin: 0 0 2rem;
        }
    }

    h3.--title {
        font-size: 4.2rem;
        line-height: 5rem;
        margin: 4rem 0 0 0;

        @include breakpoint(medium) {
            max-width: 50%;
        }
    }

    .align-middle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .address__information {
        .address__container {
            background-color: color(Gray, Gray-100);
            padding: 3rem 0;

            .grid-container {
                display: grid;
                gap: 3rem;
                grid-template-columns: 1fr;

                @include breakpoint(medium) {
                    grid-template-columns: repeat(4, 1fr);
                }

                > div {
                    @include breakpoint(medium) {
                        padding-right: 3rem;

                        &:not(:last-child) {
                            border-right: .1rem solid color(Gray, Gray-300);
                        }
                    }

                    > div {
                        padding: 1rem 2rem;

                        @include breakpoint(medium) {
                            padding: 2rem 0;
                        }

                        &.flex {
                            display: flex;
                            flex-direction: column;
                            gap: 4rem;
                        }

                        h4 {
                            font-size: 1.8rem;
                            line-height: 2.8rem;
                            font-weight: 700;
                            margin-bottom: 1rem;
                        }

                        address, p, a {
                            font-size: 1.6rem;
                            line-height: 2.8rem;

                            &:is(a) {
                                text-decoration: underline;
                            }
                        }

                        ul {
                            @include breakpoint(medium) {
                                max-width: 90%;
                            }

                            li {
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                font-size: 1.6rem;
                                line-height: 2.8rem;
                                justify-content: space-between;
                            }
                        }
                    }
                }

                .opening-hours__item, .appointment-hours__item {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                }

                .opening-hours__day, .appointment-hours__day {
                    margin-right: auto;
                }

                .opening-hours__period-container, .appointment-hours__period-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
        }
    }

    .widget__spacer {
        padding: 4rem 0;
    }
}
