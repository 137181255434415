.page-builder__wrapper {
    overflow: clip;

    @include breakpoint(small only) {
        .grid-container:not(.no-padding-mobile) {
            padding: 0 4rem;
        }
    }

    .page-builder__content-block:not(.--first) {
        margin: 4rem 0;
        @include breakpoint(medium) {
            margin: 8rem 0;
        }
    }

    .--header {
        margin: 0 0 4rem;

        @include breakpoint(medium) {
            margin: 0 0 6rem;
        }

        .page-builder__title {
            @include page-builder-title();
        }

        p {
            margin: 2rem 0 0;
            font-size: 1.5rem;
            line-height: 2.1rem;
            color: color(Gray, Gray-500);

            @include breakpoint(medium) {
                max-width: 50%;
            }
        }
    }
}
