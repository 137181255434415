.catalog__category-list {
    @include breakpoint(medium) {
        .grid-x.grid-padding-x {
            margin-left: -2.5rem;
            margin-right: -2.5rem;

            .cell {
                padding-left: 2.5rem;
                padding-right: 2.5rem;
            }
        }
    }

    .product__tile {
        @include transition;
        border-bottom: .2rem solid transparent;
        padding-bottom: 2rem;
        height: calc(100% - 3rem);
        display: flex;
        flex-flow: column;
        margin-bottom: 3rem;

        @include hover {
            border-bottom-color: color(CustomGray, Gray-200);

            .image__holder > span {
                height: 100%;
                opacity: 1;
            }
        }

        a {
            display: flex;
            flex-flow: column;
            flex: 1;

            .image__holder {
                display: flex;
                background-color: color(base, Placeholder);
                position: relative;

                > img {
                    aspect-ratio: 16/11;
                    width: 100%;
                }

                > span {
                    transition: height .3s ease-in-out, opacity .3s ease-in-out;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, .45);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: column;
                    gap: 2rem;
                    height: 0;
                    opacity: 0;

                    span {
                        color: color(base, White);
                        font-weight: 500;

                        &.--large {
                            font-size: 1.6em;
                        }
                    }

                    > img {
                        width: 50%;
                    }
                }
            }

            .product__holder {
                display: flex;
                flex-flow: column;
                padding: 1.5rem 0;
                height: 100%;

                h2, h3 {
                    flex: 1;
                    font-size: 2.2rem;
                    line-height: 3rem;
                    font-weight: 500;
                }

                .price__holder {
                    display: flex;
                    gap: .5rem;
                    margin: .5rem 0;

                    > span {
                        display: block;
                        font-size: 1.4rem;
                        color: color(CustomGray, Gray-400);

                        &.--old {
                            text-decoration: line-through;
                        }

                        &.--special {
                            color: color(base, Anthracite);
                            font-weight: 500;
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }

        .view__details {
            display: block;

            > a {
                display: inline-flex;
            }
        }
    }
}
