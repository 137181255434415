.modal__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal__wrapper {
        @include border-radius();
        background: color(base, White);
        max-width: 80vw;
        max-height: 80vh;
        min-width: 80vw;
        min-height: 25rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0 .5rem 1.5rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include breakpoint(medium) {
            min-width: 50rem;
        }

        header {
            background-color: color(base, Black);
            color: color(base, White);
            display: flex;
            align-items: center;
            padding: 1rem;

            > span {
                display: block;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 2rem;
            }

            > button {
                margin-left: auto;
                width: 2.2rem;
                height: 2.2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z' /%3E%3C/svg%3E%0A");
            }
        }

        > section {
            flex: 1;
            padding: 0 1rem 1rem;
        }
    }
}
