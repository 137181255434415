.product__configurator {
    position: relative;

    .product__configurator__group {
        border-bottom: .1rem solid color(CustomGray, Gray-200);
        padding: 0 1rem;
        scroll-margin-top: 70px;

        @include breakpoint(medium) {
            padding: 0 0 0 4rem;
        }

        dt {
            cursor: pointer;
            display: flex;
            padding: 2rem 0;
            align-items: center;
            gap: .8rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-400)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 2rem;

            &.--active {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-400)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");

                h3 {
                    color: color(base, Anthracite);

                    &::before {
                        background-color: color(base, Green);
                    }
                }
            }

            h3 {
                @include transition;
                font-size: clamp(1.8rem, 1.089rem + 0.926vw, 2rem);
                line-height: clamp(2rem, 1.089rem + 0.926vw, 2.2rem);
                position: relative;
                display: flex;
                gap: .8rem;
                color: color(Gray, Gray-600);

                &::before {
                    @include transition;
                    content: '';
                    width: 1rem;
                    height: 1rem;
                    background-color: color(CustomGray, Gray-200);
                    border-radius: 50%;

                    @include breakpoint(medium) {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -2.5rem;
                    }
                }
            }
        }

        dd {
            padding: 0 0 4rem;

            .--description {
                margin: 1rem 0 0;

                h3 {
                    font-size: 2.4rem;
                    line-height: 3.4rem;
                }
            }

            .sub__group {
                scroll-margin-top: 90px;
                padding: 1.5rem 0 0;

                &:last-child {
                    .--options {
                        margin: 0;
                    }
                }

                .--header {
                    display: flex;
                    align-items: flex-end;
                    margin: 0 0 1.5rem;

                    h5 {
                        font-size: 1.6rem;
                        line-height: 3.4rem;
                        font-weight: 500;
                        flex: 1;

                        @include breakpoint(medium) {
                            font-size: 1.8rem;
                        }
                    }

                    span {
                        font-size: 1.4rem;
                        line-height: normal;

                        @include breakpoint(medium) {
                            font-size: 1.6rem;
                        }
                    }
                }

                .--options {
                    display: grid;
                    grid-gap: 1rem;
                    margin: 0 0 3rem;
                    grid-auto-rows: 1fr;
                    grid-template-rows: auto;

                    .--option {
                        input[type="radio"]:checked + label {
                            border-color: color(base, Green);
                            box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);

                            &::before {
                                width: 3rem;
                                height: 3rem;
                            }

                            + span.--title {
                                color: color(base, Anthracite);
                                font-weight: 500;
                            }
                        }

                        input[type="radio"]:disabled + label {
                            border-color: color(CustomGray, Gray-300);
                            cursor: not-allowed;

                            &::before {
                                background-color: color(CustomGray, Gray-300);
                            }

                            + span.--title {
                                color: color(CustomGray, Gray-300);
                            }
                        }

                        label {
                            @include transition;
                            @include border-radius;
                            background-color: color(base, White);
                            display: flex;
                            cursor: pointer;
                            position: relative;
                            border: .1rem solid color(CustomGray, Gray-300);
                            padding: 2rem .5rem;
                            justify-content: center;
                            align-items: center;

                            @include hover {
                                border-color: color(CustomGray, Gray-400);
                            }

                            &::before {
                                @include transition;
                                content: '';
                                width: 0;
                                height: 0;
                                background-color: color(base, Green);
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1473' data-name='Group 1473' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat;
                                background-position: center;
                                border-radius: 50%;
                                position: absolute;
                                top: -1rem;
                                left: -1rem;
                                z-index: 1;
                            }

                            img {
                                @include transition;
                            }
                        }

                        > span {
                            display: block;

                            &.--title {
                                @include transition;
                                font-size: 1.4rem;
                                line-height: normal;
                                color: color(CustomGray, Gray-400);
                                margin: 1rem 0 0;

                                @include breakpoint(medium) {
                                    font-size: 1.6rem;
                                }
                            }

                            &.--advice {
                                font-size: 1.2rem;
                                font-weight: 500;
                                color: color(CustomGray, Gray-300);

                                @include breakpoint(medium) {
                                    font-size: 1.4rem;
                                }
                            }
                        }

                        &.--image {
                            label {
                                aspect-ratio: 16/13;
                            }
                        }

                        &.--color {
                            label {
                                aspect-ratio: 1;
                            }

                            .--title {
                                overflow: hidden;
                                word-break: break-all;
                                word-wrap: break-word;
                                font-size: 1.1rem;
                                text-overflow: ellipsis;
                                margin: .5rem 0 0;
                                text-align: center;
                            }
                        }

                        &.--counter {
                            display: flex;
                            gap: 2rem;
                            align-items: center;

                            > span {
                                font-size: 1.4rem;
                                line-height: 2.4rem;
                                font-weight: 500;

                                @include breakpoint(medium) {
                                    font-size: 1.6rem;
                                }
                            }
                        }

                        &.--text {
                            &.--geen {
                                grid-column: span 3;
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                grid-template-rows: subgrid;
                                gap: 1rem;

                                input {
                                    position: absolute;
                                }
                            }

                            label {
                                flex-direction: column;
                                padding: .6rem;
                                height: 100%;

                                > span {
                                    display: block;

                                    &.--title {
                                        @include transition;
                                        font-size: 1.4rem;
                                        line-height: normal;
                                        color: color(CustomGray, Gray-400);
                                        font-weight: 500;

                                        @include breakpoint(medium) {
                                            font-size: 1.6rem;
                                        }
                                    }

                                    &.--advice {
                                        font-size: 1.2rem;
                                        font-weight: 500;
                                        color: color(CustomGray, Gray-300);

                                        @include breakpoint(medium) {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }

                            input[type="radio"]:checked + label {
                                .--title {
                                    color: color(base, Anthracite);
                                }
                            }
                        }

                        &.--toggle {
                            display: flex;
                        }

                        &.--full-image {
                            label {
                                padding: 0;

                                .popover__content {
                                    display: none;
                                    position: absolute;
                                    bottom: calc(100% + .5rem);
                                    z-index: 2;
                                    width: 20rem;
                                    height: 20rem;
                                    border-radius: .6rem;
                                    border: .2rem solid color(CustomGray, Gray-300);

                                    &::before {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        top: 100%;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-right: 1rem solid transparent;
                                        border-left: 1rem solid transparent;
                                        border-top: 1rem solid color(CustomGray, Gray-300);
                                        border-bottom: 0;
                                    }

                                    img {
                                        border-radius: .4rem;
                                    }
                                }

                                img {
                                    border-radius: .2rem;
                                    aspect-ratio: 1;
                                }

                                @include hover() {
                                    .popover__content {
                                        display: block;
                                    }
                                }
                            }

                            .--title {
                                overflow: hidden;
                                word-wrap: break-word;
                                font-size: 1.1rem;
                                text-overflow: ellipsis;
                                margin: .5rem 0 0;
                                text-align: center;
                            }
                        }
                    }
                }

                &.--has-sub {
                    @include border-radius();
                    background: #FAFAFA;
                    padding: 1rem 2rem;
                    margin: 0 0 2rem;

                    .--header {
                        h5 {
                            font-size: 1.8rem;
                            line-height: 2.4rem;
                        }
                    }

                    .sub__sub__group .sub__group {
                        padding: 0;

                        .--header {
                            margin: 0 0 1.5rem;

                            h5 {
                                font-size: 1.6rem;
                                line-height: 2.2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .configuration__code {
        margin: 4rem 0;

        .code__block {
            background: #dfedf1;
            border-radius: 1rem;
            padding: 2rem 3rem;
            text-align: center;

            .--title {
                font-size: 2rem;
                display: flex;
                font-weight: 500;
                margin: 0 0 2rem;
                justify-content: center;
                color: #063445;
                @include breakpoint(small only) {
                    margin-bottom: 1.6rem;
                }
            }

            .--code {
                @include border-radius();
                background: color(base, White);
                text-transform: uppercase;
                font-weight: 700;
                font-size: 3.4rem;
                padding: .5rem 2rem;
                letter-spacing: .5rem;
                color: #063445;
                display: inline-flex;
                @include breakpoint(small only) {
                    font-size: 2.4rem;
                    padding: .5rem 1rem;
                }
            }

            .--actions {
                margin: 2rem 0 0;
                display: flex;
                gap: 2rem;
                justify-content: center;
                @include breakpoint(small only) {
                    margin-top: 1.6rem;
                }

                button {
                    background-repeat: no-repeat;
                    background-size: 2rem;
                    background-position: left 1rem center;
                    padding-left: 4rem;

                    &:first-child {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75' /%3E%3C/svg%3E%0A");
                    }

                    &:last-child {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        .--message {
            font-size: 1.2rem;
            display: block;
            margin: 1rem 0 0;
            color: #063445;
            text-align: center;
        }
    }
}

.configurator__appointment {
    margin: 4rem 0;
}
