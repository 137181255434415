.homepage__store-info--block-wrapper {
    h3 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    p, address {
        margin-bottom: 1rem;
        line-height: 2.4rem;
        font-size: 1.4rem;

        a {
            text-decoration: underline;
            line-height: 2.4rem;
            font-size: 1.4rem;
        }
    }

    .opening__times {
        display: grid;
        row-gap: 2rem;
        column-gap: 2rem;
        grid-template-columns: 1fr;

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 6rem;
            column-gap: 4rem;
        }

        ul {
            @include breakpoint(medium) {
                max-width: 60%;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span, div {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    @include breakpoint(small only) {
        dl:first-of-type {
            margin-bottom: 2rem;
        }
    }
}
