.global__content {
    font-size: 1.6rem;
    line-height: 2.6rem;

    h1,
    h2,
    h3 {
        margin: 0 0 1.5rem;
        font-weight: 500;
    }

    h1 {
        @include breakpoint(medium) {
            font-size: 4.4rem;
            line-height: 4.8rem;
        }
    }

    h2 {
        font-size: 3.2rem;
        line-height: 3.4rem;
    }

    h3 {
        font-size: 2rem;
        line-height: 2.4rem;
    }

    sup {
        font-size: 2rem;
        line-height: 2.4rem;
        top: 0;
    }

    p {
        margin: 0 0 1.5rem;
    }

    p strong, strong {
        font-weight: 500;
    }

    a {
        display: inline-flex;

        &:not(.--button) {
            text-decoration: underline;
        }

        @include hover {
            &:not(.--button) {
                color: color(base, Black);
            }
        }
    }

    ul {
        margin: 0 0 2rem 2rem;
        list-style: disc outside;
        font-size: 1.6rem;
        line-height: 2.6rem;
    }

    ol {
        margin: 0 0 2rem;
        list-style: none;
        font-size: 1.6rem;
        line-height: 2.6rem;
    }

    address {
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin: 0.5rem 0;
    }

    textarea {
        height: 100%;
        width: 100%;
    }

    .button__holder {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin: 0 0 1.5rem;
    }

    > *:last-child {
        &:is(ul, ol):not(.test) {
            margin-left: 2rem;
        }

        margin: 0;
    }
}
