.page-builder__two-column-content--block-wrapper, .page-builder__three-column-content--block-wrapper {
    .two-column-content__columns, .three-column-content__columns {
        display: flex;
        gap: 1rem;

        &:not(.three-column-content__columns) {
            gap: 6rem;
        }

        @include breakpoint(small only) {
            flex-direction: column;
        }

        .--column {
            .--media__holder {
                @include breakpoint(medium) {
                    height: 36rem;
                    overflow: hidden;
                }

                > div {
                    @include breakpoint(medium) {
                        height: 36rem;
                    }
                }

                > a {
                    display: block;
                    overflow: hidden;

                    @include breakpoint(medium) {
                        height: 36rem;
                    }

                    img {
                        @include transition();
                    }

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }

                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .--content {
                @include custom-list-color();
                padding: 2rem 0 0;

                > div {
                    margin: 0 0 1.5rem;
                }
            }
        }
    }
}
