.homepage__blog--block-wrapper {
    .blog__container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        .blog__post > a {
            aspect-ratio: 433/289;
        }

        @include breakpoint(medium) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: .5rem;

            .blog__post > h3 {
                max-width: 80%;
            }
        }

        @include breakpoint(small only) {
            .blog__post {
                margin: 0 0 3rem;
            }
        }
    }

    .--actions {
        margin: 4rem 0 0;
    }
}
