input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::placeholder {
    color: color(CustomGray, Gray-400);
    opacity: .8;
}

:-ms-input-placeholder {
    color: color(CustomGray, Gray-400);
    opacity: .8;
}

::-ms-input-placeholder {
    color: color(CustomGray, Gray-400);
    opacity: .8;
}

input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select, textarea {
    @include border-radius();
    height: 4.6rem;
    border: .1rem solid color(CustomGray, Gray-200);
    width: 100%;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    background-color: color(base, White);

    &:not(:read-only):focus {
        border-color: color(base, Green);
        box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);
    }

    &.invalid:not(:focus) {
        border-color: color(base, Red);
    }

    &:disabled {
        background-color: color(CustomGray, Gray-150);
        cursor: not-allowed;
    }
}

textarea {
    height: auto;
    min-height: 12rem;
    padding: 1.5rem 2rem;
    resize: vertical;
}

select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_1533' data-name='Group 1533' transform='translate(-935 -332)'%3E%3Crect id='Rectangle_250' data-name='Rectangle 250' width='24' height='24' transform='translate(935 332)' fill='none'/%3E%3Cpath id='Path_3311' data-name='Path 3311' d='M11.841.354,6.1,6.1.354.354' transform='translate(941.403 341.275)' fill='none' stroke='%23212121' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 1.6rem center;
    padding-right: 4rem;

    option {
        font-size: 1.4rem;
    }
}

input[type='checkbox'] {
    border-radius: .4rem;
    background: color(base, White);
    width: 3rem;
    height: 3rem;
    border: 0.1rem solid color(CustomGray, Gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 3rem;

    &:checked {
        border-radius: .4rem;
        background: color(base, Green);
        border-color: color(base, Green);
        box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);

        &::after {
            content: '';
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1588' data-name='Group 1588' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }
}

.field__holder {
    position: relative;

    > input {
        @include transition();
    }

    > label {
        @include transition;
        height: 4.4rem;
        position: absolute;
        font-size: 1.4rem;
        color: color(CustomGray, Gray-400);
        left: .1rem;
        right: .1rem;
        top: .1rem;
        bottom: .1rem;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        cursor: text;
    }

    > input:disabled + label {
        cursor: not-allowed;
    }

    &.--placeholder {
        > input {
            padding: 1.8rem 2rem .2rem;
        }

        > label {
            height: 1.8rem;
            font-size: 1.2rem;
            color: color(CustomGray, Gray-400);
            align-items: baseline;
            padding: 0;
        }
    }
}

.input__holder {
    .--error {
        font-size: 1.2rem;
        color: color(base, Red);
        display: inline-flex;
        margin: .5rem 0 0;
    }
}

.field__group {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;

    label {
        font-weight: 500;
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;

        &.flex {
            align-items: center;
            gap: 0.5rem;
        }
    }

    .field-error {
        color: color(alert, Error);
        font-size: 1.2rem;
        margin-top: 0.5rem;
        padding-left: 1rem;
    }
}

.field__container {
    margin: 1rem 0 2rem;

    &.--flex {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        &:has(> .field__container) {
            gap: 2rem;
        }

        > *:not(input) {
            flex: 1;
            margin: 0;
        }
    }

    > input[type='checkbox'], > input[type='radio'] {
        cursor: pointer;

        + label {
            padding: 0;
            cursor: pointer;
            line-height: normal;
            font-size: 1.4rem;
            @include hover {
                text-decoration: underline;
            }
        }
    }

    label, span.label {
        display: block;
        font-weight: 500;
        font-size: 1.4rem;
        padding-bottom: 0.5rem;

        &.flex {
            align-items: center;
            gap: 0.5rem;
        }
    }

    .validator-err {
        color: color(alert, Error);
        font-size: 1.2rem;
        margin: 0.5rem 0 0;
        padding-left: 1rem;
        line-height: normal;

        &:not(:first-of-type) {
            display: none;
        }
    }

    &:has(> .validator-err) {
        input {
            background-size: 1.6rem;
            background-position: right 1rem center;
            background-repeat: no-repeat;
            border-color: color(alert, Error);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.8' stroke='#{color(alert, Error)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z' /%3E%3C/svg%3E%0A");
        }

        select {
            border-color: color(alert, Error);
        }
    }

    .field-error {
        display: block;
        color: color(alert, Error);
        font-size: 1.2rem;
        margin-top: 0.5rem;
        padding-left: 1rem;
    }

    > p {
        font-size: 1.3rem;
        line-height: normal;
        color: color(Gray, Gray-500);
        margin: .5rem 0 0;
    }

    label.file-upload {
        @include transition();
        @include border-radius();
        cursor: pointer;
        overflow: hidden;
        position: relative;
        padding: 2.4rem;
        border: .1rem solid color(Gray, Gray-400);
        background-color: color(Gray, Gray-100);
        color: color(Gray, Gray-800);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        gap: 0;
        width: 100%;
        justify-content: center;

        @include breakpoint(medium) {
            width: 35rem;

            &.dropzone-dragging, &:hover {
                box-shadow: rgba(0, 0, 0, 0.1) 0 1rem 1.5rem -.3rem, rgba(0, 0, 0, 0.05) 0 .4rem .6rem -.2rem;
            }
        }

        .--overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: color(base, White);
            opacity: .2;
            z-index: 1;
        }

        .--content {
            @include border-radius();
            padding: 2.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .8rem;
            z-index: 2;
            background-color: rgba(255, 255, 255, .75);;

            .--image {
                display: block;
                width: 3.2rem;
                height: 3.2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-800)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z' /%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            .--title {
                display: block;
            }

            small {
                font-size: 1.1rem;
                color: color(Gray, Gray-700);
                line-height: normal;
                margin: 0 0 .8rem;
            }
        }
    }
}

.group__container {
    .input__checkbox {
        margin: 1rem 0 2rem;

        > p {
            display: block;
            font-weight: 500;
            font-size: 1.4rem;
            padding-bottom: 0.5rem;
        }

        > div {
            position: relative;
            display: flex;
            gap: 1rem;
            align-items: center;

            input {
                cursor: pointer;
            }

            label {
                cursor: pointer;
                font-weight: 500;
                font-size: 1.4rem;
            }

            .validator-err {
                color: color(alert, Error);
                font-size: 1.2rem;
                line-height: normal;
                order: 4;
            }
        }
    }
}

.field__actions {
    margin: 2rem 0 0;
    display: flex;
    gap: 2rem;
    align-items: center;

    &:not(.--small) {
        justify-content: center;

        > * {
            flex: 1;
        }
    }

    .--button {
        min-width: 15rem;
    }

    a:not(.--button) {
        font-size: 1.3rem;
        color: color(base, Anthracite);

        @include hover {
            text-decoration: underline;
        }
    }
}

.--counter-input {
    display: flex;
    align-items: center;
    flex: 1;

    button {
        @include transition;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        cursor: pointer;
        flex: 0 0 4.6rem;
        height: 4.6rem;
        border: .1rem solid color(CustomGray, Gray-400);
        border-radius: .4rem 0 0 .4rem;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_1499' data-name='Group 1499' transform='translate(-0.209 -0.209)'%3E%3Crect id='Rectangle_203' data-name='Rectangle 203' width='20' height='20' transform='translate(0.209 0.209)' fill='none'/%3E%3Cline id='Line_82' data-name='Line 82' x2='13.333' transform='translate(3.172 9.839)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line_83' data-name='Line 83' y2='13.333' transform='translate(9.839 3.172)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' opacity='0'/%3E%3C/g%3E%3C/svg%3E%0A");

        &:last-child {
            border-radius: 0 .4rem .4rem 0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_1498' data-name='Group 1498' transform='translate(-0.209 -0.209)'%3E%3Crect id='Rectangle_203' data-name='Rectangle 203' width='20' height='20' transform='translate(0.209 0.209)' fill='none'/%3E%3Cline id='Line_82' data-name='Line 82' x2='13.333' transform='translate(3.172 9.839)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line_83' data-name='Line 83' y2='13.333' transform='translate(9.839 3.172)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
        }

        &:disabled {
            cursor: not-allowed;
            border-color: color(CustomGray, Gray-300);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_1499' data-name='Group 1499' transform='translate(-0.209 -0.209)'%3E%3Crect id='Rectangle_203' data-name='Rectangle 203' width='20' height='20' transform='translate(0.209 0.209)' fill='none'/%3E%3Cline id='Line_82' data-name='Line 82' x2='13.333' transform='translate(3.172 9.839)' fill='none' stroke='#{color(CustomGray, Gray-300)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line_83' data-name='Line 83' y2='13.333' transform='translate(9.839 3.172)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' opacity='0'/%3E%3C/g%3E%3C/svg%3E%0A");

            &:last-child {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='Group_1498' data-name='Group 1498' transform='translate(-0.209 -0.209)'%3E%3Crect id='Rectangle_203' data-name='Rectangle 203' width='20' height='20' transform='translate(0.209 0.209)' fill='none'/%3E%3Cline id='Line_82' data-name='Line 82' x2='13.333' transform='translate(3.172 9.839)' fill='none' stroke='#{color(CustomGray, Gray-300)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cline id='Line_83' data-name='Line 83' y2='13.333' transform='translate(9.839 3.172)' fill='none' stroke='#{color(CustomGray, Gray-300)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }
    }

    > span {
        background-color: color(base, White);
        display: flex;
        align-items: center;
        width: fit-content;
        height: 4.6rem;
        border-radius: 0;
        border: none;
        font-size: 1.6rem;
        padding: 0 1rem;
        border-top: .1rem solid color(CustomGray, Gray-300);
        border-bottom: .1rem solid color(CustomGray, Gray-300);
        overflow: hidden;
        min-width: 5.2rem;
        justify-content: center;
    }
}

.--toggle-input {
    @include border-radius;
    display: flex;
    margin: 1rem 0 0;
    background: color(CustomGray, Gray-200);

    button {
        @include border-radius;
        border: .1rem solid color(CustomGray, Gray-200);
        flex: 1;
        gap: .8rem;
        font-size: 1.6rem;
        line-height: 4.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .2s ease-out;
        background: linear-gradient(to right, transparent 50%, color(base, White) 50%) left;
        background-size: 200%;
        color: color(CustomGray, Gray-400);
        min-width: 9rem;
        padding: 0 2rem;
        white-space: nowrap;

        &:first-child {
            border-right: none;
        }

        &:last-child {
            background: linear-gradient(to left, transparent 50%, color(base, White) 50%) right;
            background-size: 200%;
            border-left: none;
        }

        &:not(:disabled).--active {
            background-position: right bottom;
            color: color(base, Anthracite);
            border: .1rem solid color(base, Green);
            box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);

            &:last-child {
                background-position: left bottom;
            }
        }

        &:disabled {
            cursor: not-allowed;
        }
    }
}

.salesforce__form {
    margin: 2rem 0 0;

    .--intro {
        margin: 0 0 2rem;

        h3 {
            margin: 0 0 1rem;
        }

        p {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }

    section {
        position: relative;

        .success__holder {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.65);
            z-index: 1;

            .--success {
                position: absolute;
                top: 2rem;
                left: 2rem;
                right: 2rem;
                bottom: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2rem;
                background-color: #dff1e0;
                z-index: 2;
                border-radius: 1rem;

                > button {
                    position: absolute;
                    width: 3rem;
                    height: 3rem;
                    right: -.8rem;
                    top: -.8rem;
                    border-radius: 50%;
                    background-color: color(base, White);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 1.3rem;
                    box-shadow: rgba(0, 0, 0, 0.05) 0 .6rem 2.4rem 0, rgba(0, 0, 0, 0.08) 0 0 0 .1rem;
                }

                > div {
                    text-align: center;

                    > * {
                        color: #094f09;
                    }

                    h2 {
                        margin: 0 0 1rem;
                    }
                }
            }
        }
    }
}
