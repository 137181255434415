.global__message {
    font-size: 1.3rem;
    line-height: 2rem;
    display: inline-block;
    &.--margin {
        margin: 1rem 0;
    }
    &.--margin--top {
        margin: 1rem 0 0;
    }
    &.--margin--bottom {
        margin: 0 0 1rem;
    }
    &.--full-width {
        width: 100%;
    }
    &.--space {
        padding: 1rem 1.5rem;
    }
    @each $messageStyle in map-keys(map-get($color-palette, alert)) {
        &.--#{to-lower-case($messageStyle)} {
            color: map-get(map-get($color-palette, alert), $messageStyle);
        }
    }
    &--bg {
        @extend .global__message;
        border-radius: 0.3rem;
        padding: 0.5rem 1rem;
        @each $messageStyle in map-keys(map-get($color-palette, alert)) {
            &.--#{to-lower-case($messageStyle)} {
                background-color: map-get(map-get($color-palette, alert-backgrounds), $messageStyle);
                border: 0.1rem solid map-get(map-get($color-palette, alert), $messageStyle);
            }
        }
    }
    a {
        font-size: 1.3rem;
        line-height: 2rem;
        text-decoration: underline;
    }
}

.global__messages {
    position: fixed;
    inset: auto 1rem 1rem auto;
    z-index: 7;
    max-height: 50vh;
    overflow: hidden;
    li {
        max-width: 30rem;
        margin: 1rem 0 0;
        padding: 1rem;
        display: block;
        line-height: 1.8rem;
    }
}
