header {
    @include transition;

    .sticky {
        @include transition;
        background-color: color(base, White);

        &.is-stuck {
            background-color: color(base, White);
        }
    }

    .header__container {
        @include breakpoint(small only) {
            padding: 1.5rem 0;
        }

        > .grid-container {
            display: flex;
            align-items: center;

            @include breakpoint(medium) {
                justify-content: space-between;
            }

            .--logo {
                display: flex;

                @include breakpoint(small only) {
                    width: 12.5rem;
                }
            }

            .header__actions {
                display: flex;
                align-items: center;
                gap: 1.6rem;

                @include breakpoint(small only) {
                    margin-left: auto;
                }

                .language__switcher {
                    cursor: pointer;
                    position: relative;
                    font-weight: 500;
                    padding: .5rem;
                    margin: -.5rem;
                    border-radius: .4rem .4rem 0 0;

                    @media screen and (max-width: 870px) {
                        display: none;
                    }

                    > span {
                        display: flex;
                        font-size: 1.4rem;
                        align-items: center;
                        gap: .5rem;
                        text-transform: uppercase;

                        &::before {
                            content: '';
                            width: 2.4rem;
                            height: 2.4rem;
                            background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1232' data-name='Group 1232' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_164' data-name='Rectangle 164' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_7' data-name='Ellipse 7' cx='9' cy='9' r='9' transform='translate(3 3)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3275' data-name='Path 3275' d='M88,41a12.9,12.9,0,0,0,3.214,8.765.728.728,0,0,0,1.072,0A12.9,12.9,0,0,0,95.5,41a12.9,12.9,0,0,0-3.214-8.765.728.728,0,0,0-1.073,0A12.9,12.9,0,0,0,88,41Z' transform='translate(-79.75 -28.999)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_78' data-name='Line 78' x2='18' transform='translate(3 12)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                        }

                        &::after {
                            content: '';
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='4px' viewBox='0 0 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group--2' stroke='%23212121'%3E%3Cpolyline id='Path_3311' points='8 0 4 4 0 1.11022302e-16'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                            width: .8rem;
                            height: .4rem;
                        }
                    }

                    > ul {
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        background: color(base, White);
                        border-radius: 0 0 .4rem .4rem;

                        li {
                            display: flex;
                            justify-content: flex-end;
                            padding-right: 1.2rem;

                            a {
                                display: flex;
                                gap: .5rem;
                                margin: .5rem 0;
                                text-transform: uppercase;
                            }
                        }
                    }

                    @include hover() {
                        > ul {
                            display: block;
                        }
                    }
                }

                > a, > button, section > div > a, section > a, section > button {
                    display: flex;
                    width: 2.4rem;
                    height: 2.4rem;

                    &.--store {
                        background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1365' data-name='Group 1365' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_213' data-name='Rectangle 213' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_25' data-name='Ellipse 25' cx='3' cy='3' r='3' transform='translate(9 6.75)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3304' data-name='Path 3304' d='M63,31.5c0,6.75-7.5,12-7.5,12S48,38.25,48,31.5a7.5,7.5,0,0,1,15,0Z' transform='translate(-43.5 -21.75)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    }

                    &.--cart {
                        background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1233' data-name='Group 1233' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_165' data-name='Rectangle 165' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_8' data-name='Ellipse 8' cx='1.5' cy='1.5' r='1.5' transform='translate(6 18.75)'/%3E%3Ccircle id='Ellipse_9' data-name='Ellipse 9' cx='1.5' cy='1.5' r='1.5' transform='translate(15.75 18.75)'/%3E%3Cpath id='Path_3276' data-name='Path 3276' d='M11.215,35.75H28.25l-2.674,8.692A1.5,1.5,0,0,1,24.142,45.5h-9.01a1.5,1.5,0,0,1-1.443-1.088L10.3,32.544A.75.75,0,0,0,9.577,32H8' transform='translate(-7.25 -29)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    }

                    &.--search {
                        background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1234' data-name='Group 1234' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_166' data-name='Rectangle 166' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_10' data-name='Ellipse 10' cx='7.5' cy='7.5' r='7.5' transform='translate(3 3)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_79' data-name='Line 79' x2='5.197' y2='5.197' transform='translate(15.803 15.803)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    }

                    &.--customer {
                        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_1411' data-name='Group 1411' transform='translate(11698 17818)'%3E%3Crect id='Rectangle_223' data-name='Rectangle 223' width='24' height='24' transform='translate(-11698 -17818)' fill='none'/%3E%3Cellipse id='Ellipse_31' data-name='Ellipse 31' cx='4.794' cy='4.794' rx='4.794' ry='4.794' transform='translate(-11690.794 -17814.91)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3306' data-name='Path 3306' d='M.5,5.562C.5,2.118,3.318.5,6.762.5h3.949c3.444,0,6.262,1.618,6.262,5.062' transform='translate(-11694.736 -17802.652)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }

            .mobile__menu button {
                background-color: color(base, White);
                background-image: url("data:image/svg+xml,%0A%3Csvg width='12.857px' height='7.857px' viewBox='0 0 12.857 7.857' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Group' fill='%23000000' fill-rule='nonzero' stroke='%23000000'%3E%3Cline x1='0' y1='0.5' x2='12.857' y2='0.5' id='Line_92'%3E%3C/line%3E%3Cline x1='0' y1='7.357' x2='12.857' y2='7.357' id='Line_93'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                width: 3.4rem;
                height: 3.4rem;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: center;
                margin-left: 1.5rem;
            }
        }

        &.header__container-funnel {
            padding: 0;

            .usps-container {
                background-color: #000;

                ul {
                    display: flex;
                    flex-direction: row;
                    gap: 24px;

                    @include breakpoint(medium only) {
                        gap: 10px;
                    }

                    @include breakpoint(medium only) {
                        justify-content: center;
                    }

                    li {
                        display: flex;
                        flex-direction: row;
                        gap: 2px;
                        justify-content: center;
                        transition: margin-left 0.2s;

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 13px 10px;
                            background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.1623 9.8375L0.549805 5.225L1.9748 3.8L5.1623 7.00625L12.0248 0.125L13.4498 1.55L5.1623 9.8375Z' fill='%2300D85F'/%3E%3C/svg%3E%0A");
                        }

                        padding: 8px 0;
                        font-size: 14px;
                        line-height: 20px;
                        color: #fff;
                        white-space: nowrap;

                        @include breakpoint(medium only) {
                            font-size: 13px;
                        }

                        @include breakpoint(small only) {
                            flex: 1;
                            min-width: 100%;
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }

            .--logo {
                margin: 16px 0;
            }

            .header__actions {
                .language__switcher {
                    background-color: #F4F4F5;
                    border-radius: 32px;
                    padding: .5rem .7rem;

                    @include breakpoint(medium) {
                        display: block !important;
                    }
                }

                .--store {
                    display: none;
                }

                .language__switcher--mobile {
                    align-items: center;
                    gap: 1rem;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url("data:image/svg+xml,%3Csvg id='Group_1444' data-name='Group 1444' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_164' data-name='Rectangle 164' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_7' data-name='Ellipse 7' cx='9' cy='9' r='9' transform='translate(3 3)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3275' data-name='Path 3275' d='M88,41a12.9,12.9,0,0,0,3.214,8.765.728.728,0,0,0,1.072,0A12.9,12.9,0,0,0,95.5,41a12.9,12.9,0,0,0-3.214-8.765.728.728,0,0,0-1.073,0A12.9,12.9,0,0,0,88,41Z' transform='translate(-79.75 -28.999)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_78' data-name='Line 78' x2='18' transform='translate(3 12)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    padding-left: 4rem;
                    display: none;

                    @include breakpoint(small only) {
                        display: flex;
                    }

                    > span, > a {
                        font-size: 1.6rem;
                        color: color(CustomGray, Gray-400);
                        font-weight: 500;
                        line-height: 1;
                        text-transform: uppercase;

                        &:is(span) {
                            color: color(base, Anthracite);
                        }
                    }
                }
            }
        }
    }

    @include hover {
        background-color: color(base, White);
    }
}
