.tabs__container {
    display: flex;
    flex-wrap: wrap;
    margin: 4rem 0 0;

    label {
        @include transition;
        order: 1;
        color: color(base, Anthracite);
        padding: 0 0 .5rem;
        font-size: 2.4rem;
        display: block;
        margin-right: 5rem;
        cursor: pointer;
        border-bottom: .2rem solid transparent;
        font-weight: 500;
    }

    .--panel {
        order: 99;
        flex-grow: 1;
        width: 100%;
        display: none;
        padding: 2rem 0;
    }

    input[type="radio"]:checked + label {
        border-color: color(base, Anthracite);
    }

    input[type="radio"]:checked + label + .--panel {
        display: block;
    }

    @include breakpoint(small only) {
        .--panel, label {
            order: initial;
        }

        label {
            width: 100%;
            margin-right: 0;
        }
    }
}
