.homepage__review--block-wrapper {
    background: color(CustomGray, Gray-150-85);
    padding: 4rem 0 6rem;

    .page-builder__title {
        margin: 0 0 2rem;
    }

    .--relative {
        position: relative;

        @include breakpoint(medium) {
            margin: 0 -4rem;
        }

        .blaze-slider {
            @include absolute-button();

            > button {
                top: 40%;

                @include breakpoint(small only) {
                    display: none;
                }
            }

            .blaze-pagination {
                margin: 4rem 0 0;
                justify-content: center;
                align-items: center;
            }

            .blaze-track {
                .--content {
                    display: flex;
                    flex-flow: column;
                    gap: .5rem;

                    @include breakpoint(medium) {
                        padding: 0 4rem;
                        &:not(:first-child) {
                            border-left: .1rem solid color(Gray, Gray-300);
                        }
                    }

                    blockquote {
                        font-size: 1.6rem;
                        line-height: 2.6rem;
                        flex: 1;

                        button {
                            font-size: 1.3rem;
                            display: block;
                            text-decoration: underline;
                            margin: .5rem 0 0;
                        }
                    }

                    cite {
                        margin: 1rem 0 0;
                        font-size: 1.3rem;
                        font-weight: 500;
                        font-style: italic;
                        color: color(CustomGray, Gray-400);
                    }

                    .--footer {
                        display: flex;
                        gap: 2rem;
                        align-items: center;
                        margin: 1rem 0 0;

                        .--rating {
                            width: 77px;
                            height: 14px;
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='77px' height='14px' viewBox='0 0 77 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23D1D1D1'%3E%3Cpolygon id='Star' points='7 10.5 2.88550323 12.663119 3.67130219 8.08155948 0.342604386 4.83688104 4.94275162 4.16844052 7 0 9.05724838 4.16844052 13.6573956 4.83688104 10.3286978 8.08155948 11.1144968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy' points='22.75 10.5 18.6355032 12.663119 19.4213022 8.08155948 16.0926044 4.83688104 20.6927516 4.16844052 22.75 0 24.8072484 4.16844052 29.4073956 4.83688104 26.0786978 8.08155948 26.8644968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy-2' points='38.5 10.5 34.3855032 12.663119 35.1713022 8.08155948 31.8426044 4.83688104 36.4427516 4.16844052 38.5 0 40.5572484 4.16844052 45.1573956 4.83688104 41.8286978 8.08155948 42.6144968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy-3' points='54.25 10.5 50.1355032 12.663119 50.9213022 8.08155948 47.5926044 4.83688104 52.1927516 4.16844052 54.25 0 56.3072484 4.16844052 60.9073956 4.83688104 57.5786978 8.08155948 58.3644968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy-4' points='70 10.5 65.8855032 12.663119 66.6713022 8.08155948 63.3426044 4.83688104 67.9427516 4.16844052 70 0 72.0572484 4.16844052 76.6573956 4.83688104 73.3286978 8.08155948 74.1144968 12.663119'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

                            > span {
                                display: block;
                                height: 14px;
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='77px' height='14px' viewBox='0 0 77 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23F0D265'%3E%3Cpolygon id='Star' points='7 10.5 2.88550323 12.663119 3.67130219 8.08155948 0.342604386 4.83688104 4.94275162 4.16844052 7 0 9.05724838 4.16844052 13.6573956 4.83688104 10.3286978 8.08155948 11.1144968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy' points='22.75 10.5 18.6355032 12.663119 19.4213022 8.08155948 16.0926044 4.83688104 20.6927516 4.16844052 22.75 0 24.8072484 4.16844052 29.4073956 4.83688104 26.0786978 8.08155948 26.8644968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy-2' points='38.5 10.5 34.3855032 12.663119 35.1713022 8.08155948 31.8426044 4.83688104 36.4427516 4.16844052 38.5 0 40.5572484 4.16844052 45.1573956 4.83688104 41.8286978 8.08155948 42.6144968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy-3' points='54.25 10.5 50.1355032 12.663119 50.9213022 8.08155948 47.5926044 4.83688104 52.1927516 4.16844052 54.25 0 56.3072484 4.16844052 60.9073956 4.83688104 57.5786978 8.08155948 58.3644968 12.663119'%3E%3C/polygon%3E%3Cpolygon id='Star-Copy-4' points='70 10.5 65.8855032 12.663119 66.6713022 8.08155948 63.3426044 4.83688104 67.9427516 4.16844052 70 0 72.0572484 4.16844052 76.6573956 4.83688104 73.3286978 8.08155948 74.1144968 12.663119'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                            }
                        }

                        time {
                            font-size: 1.2rem;
                            color: color(CustomGray, Gray-400);
                        }
                    }
                }
            }
        }
    }
}
