.homepage__category--block-wrapper {
    position: relative;

    .category__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        @include breakpoint(medium) {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 12rem;
            grid-gap: 4rem;
        }

        @include tablet-breakpoint() {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 4rem;
            grid-gap: 2rem;
        }

        .homepage__category--block {
            display: block;
            margin: 0 0 2rem;

            @include breakpoint(medium) {
                display: flex;
                gap: 2rem;
                align-items: center;
                margin: 0;
            }

            > span {
                display: flex;
                flex: 1;

                &.--image {
                    overflow: hidden;
                    @include breakpoint(small only) {
                        aspect-ratio: 7/6;
                        > img {
                            aspect-ratio: 7/6;
                        }
                    }

                    img {
                        @include transition;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                }

                &.--title {
                    font-weight: 500;
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    margin: 1rem 0 0;

                    @include breakpoint(medium) {
                        font-size: 2rem;
                        line-height: 2.4rem;
                        margin: 0;
                    }
                }
            }

            @include hover {
                .--image img {
                    transform: scale(1.25);
                }
                
                .--title {
                    text-decoration: underline;
                }
            }
        }
    }
}
