.catalog-category-page {
    .category__header {
        margin: 0 0 2rem;

        h1 {
            font-size: 3.6rem;
            line-height: 4rem;
        }

        .catalog__short-description {
            h2 {
                font-size: 2rem;
                line-height: 2.4rem;
            }

            p {
                font-size: 1.4rem;
                line-height: 2.4rem;
            }
        }
    }

    .catalog__category-list {
        position: relative;

        > .--loading {
            @include loading;
            align-items: flex-start;

            .--spinner {
                margin: 10% 0;
            }
        }
    }

    .toolbar__container {
        position: relative;

        .catalog-category__toolbar {
            margin: 0 0 2rem;

            &:not(.--placeholder) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 2rem;

                .mobile-filter {
                    flex: 1;

                    button {
                        width: 100%;
                        height: 4.6rem;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: left 1.5rem center;
                        background-size: 2.4rem;
                    }
                }

                .catalog-category__sorter {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    label {
                        font-size: 1.4rem;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .catalog__description {
        margin: 4rem 0;
    }

    .category__content {
        border-top: .1rem solid color(CustomGray, Gray-200);
        padding: 4rem 0 0;
        margin: 4rem 0 0;

        .description__block {
            margin: 0 0 4rem;

            .--content {
                @include breakpoint(small only) {
                    margin: 2rem 0 0;
                }

                h2 {
                    margin: 0 0 2rem;
                }

                > a {
                    margin: 2rem 0 0;
                }
            }

            .--flex-center {
                display: flex;
                align-items: center;
            }
        }

        .category__information {
            .--header {
                text-align: center;
                margin: 6rem 0;

                p {
                    margin: 1rem auto 0;

                    @include breakpoint(medium) {
                        max-width: 40%;
                    }
                }
            }

            .--content {
                @include breakpoint(small only) {
                    margin: 0 0 2rem;
                }

                h3 {
                    margin: 2rem 0;
                }

                .--actions {
                    text-align: center;
                    margin: 2rem 0 0;
                }
            }
        }
    }
}
