.sticky__media__container {
    @include breakpoint(medium) {
        padding: 0 2rem 0 0;
        top: 6.4rem;
        position: sticky;
    }

    .configurator__product__media {
        @include breakpoint(small only) {
            margin: 0 -1rem;
        }

        &.--preview {
            position: relative;

            .configuration__image {
                @include border-radius();
                position: fixed;
                border: .1rem solid color(CustomGray, Gray-200);
                top: 8rem;
                left: 2rem;
                right: 2rem;
                background-color: color(base, White);
                z-index: 3;
                max-width: 20rem;

                .--background {
                    padding: 0;
                }

            }
        }

        .--background {
            @include border-radius();
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: color(Gray, Gray-100);
            aspect-ratio: 173/140;
            position: relative;
            width: 100%;
            height: 100%;

            .iiz__hint {
                @include border-radius();
                right: 2rem;
                background-color: color(base, White);
            }

            .blaze-slider {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                .blaze-container {
                    width: 100%;
                    height: 100%;

                    .blaze-track-container {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .configuration__pagination {
                position: absolute;
                bottom: 2rem;
                z-index: 1;
                display: flex;
                justify-content: center;
                gap: 1rem;

                > button {
                    width: 1.5rem;
                    height: 1.5rem;
                    background: color(base, White);
                    border-radius: 50%;

                    &.--active {
                        background: color(base, Red);
                    }
                }
            }
        }
    }

    .product__totals {
        margin: 3rem 0;

        @include breakpoint(medium) {
            margin: 2rem 0 0;
        }

        @include breakpoint(small only) {
            &.--sticky {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                border-top: .1rem solid color(CustomGray, Gray-200);
                background: color(base, White);
                padding: 2rem 1rem;
                margin: 0;
                z-index: 3;
            }
        }

        .--totals {
            display: flex;
            align-items: center;
            margin: 0 0 2rem;

            span {
                display: flex;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2rem;

                @include breakpoint(medium) {
                    font-size: 3.2rem;
                    line-height: 3.4rem;
                }

                &.--price {
                    margin-left: auto;
                    gap: .5rem;
                    align-items: flex-end;

                    small {
                        line-height: normal;
                        font-size: 1.4rem;

                        @include breakpoint(medium) {
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                        }
                    }
                }
            }
        }

        .product__save {
            @include breakpoint(small only) {
                margin: 1.5rem 0 0;
            }

            button {
                @include default-button();
                font-size: 1.4rem;
                margin: 2rem 0 0;
                padding: 1.2rem 1.6rem;

                &.--success {
                    background-color: color(alert, Success);
                    text-decoration: none;
                }

                &:disabled {
                    cursor: not-allowed;
                }
            }

            > span {
                display: block;
                font-size: 1.1rem;
                margin: .5rem 0 0;
                color: color(alert, Error);
            }
        }
    }
}

.product__media-gallery {
    .--gallery {
        position: relative;

        .blaze-slider {
            @include absolute-button();
        }

        picture {
            display: flex;
        }
    }

    .--thumbnails {
        position: relative;
        margin: 1rem 0;

        .blaze-slider {
            @include absolute-button();
        }

        .blaze-track-container {
            padding: 2rem 1rem;
            margin: -2rem -1rem;
        }

        .--thumb {
            display: block;
            cursor: pointer;
            border: .1rem solid transparent;

            picture {
                display: flex;
                width: 100%;
                height: auto;

                img {
                    object-fit: cover;
                }
            }

            &.--active {
                border-color: color(base, Green);
                box-shadow: 0 0 1rem rgba(0, 215, 94, 0.3);
            }
        }
    }
}
