.blog__post {
    a {
        display: flex;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            aspect-ratio: 3/2;
        }
    }

    > a {
        overflow: hidden;

        > img {
            @include transition;
        }

        @include hover {
            > img {
                transform: scale(1.05);
            }
        }
    }

    .--header {
        display: flex;
        margin: 2rem 0 0;
        align-items: center;
        gap: .5rem;

        > span, > time {
            font-size: 1.4rem;
            line-height: 2.4rem;
            color: color(CustomGray, Gray-400);
            font-weight: 500;
        }

        > span::after {
            content: '•';
            margin: 0 0 0 .5rem;
        }
    }

    h3 a {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.4rem;

        @include hover {
            text-decoration: underline;
        }
    }
}

.blog__home {
    .blog__post {
        margin: 0 0 4rem;
    }
}

.blog__view {

    .--header {
        display: flex;
        align-items: center;
        border-bottom: .1rem solid color(CustomGray, Gray-200);
        padding: 0 0 1rem;
        margin: 0 0 1rem;

        @include breakpoint(small only) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
        }

        .--date {
            display: flex;
            align-items: center;
            gap: 1rem;

            span {
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: color(CustomGray, Gray-400);
            }

            time {
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: color(CustomGray, Gray-400);
                font-weight: 500;
            }
        }

        .--social-share {
            display: flex;
            gap: 1rem;
            align-items: center;

            @include breakpoint(medium) {
                margin-left: auto;
            }

            span {
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: color(CustomGray, Gray-400);
            }

            a {
                @include transition;
                display: block;
                height: 3rem;
                width: 3rem;
                background-color: color(base, Anthracite);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 2.2rem;
                border-radius: 50%;
                border: .1rem solid transparent;

                &.fb {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' stroke-width='1' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3' /%3E%3C/svg%3E");
                }

                &.pi {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-pinterest' width='44' height='44' viewBox='0 0 24 24' stroke-width='1' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M8 20l4 -9' /%3E%3Cpath d='M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7' /%3E%3Cpath d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' /%3E%3C/svg%3E");
                }

                &.wa {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-whatsapp' width='44' height='44' viewBox='0 0 24 24' stroke-width='1' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9' /%3E%3Cpath d='M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1' /%3E%3C/svg%3E");
                }

                @include hover {
                    border-color: color(base, Green);
                    box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);
                }
            }
        }
    }
}

.blog__navigation {
    margin: 0 0 2rem;

    @include breakpoint(medium) {
        margin: 0;
    }

    h5 {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
        padding: 0.8rem 0;
    }

    ul {
        li {
            a {
                font-size: 1.5rem;
                line-height: 2.7rem;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.blog__category {
    h1 {
        font-size: 2.8rem;
        line-height: 3.2rem;
        margin: 0 0 3rem;
    }
}
