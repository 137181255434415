@font-face {
    font-family: 'Beausite Classic';
    src: local('Beausite Classic Web Clear'), local('BeausiteClassicWeb-Clear'),
    url('/area/web/default/assets/fonts/BeausiteClassicWeb-Clear.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/BeausiteClassicWeb-Clear.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Beausite Classic';
    src: local('Beausite Classic Web Medium'), local('BeausiteClassicWeb-Medium'),
    url('/area/web/default/assets/fonts/BeausiteClassicWeb-Medium.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/BeausiteClassicWeb-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}
