.homepage__store-locator--block-wrapper {

    .store-locator__container {
        position: relative;

        @include breakpoint(small only) {
            padding: 0 2rem;
        }

        .--map {
            width: 100%;
            height: 73rem;
        }

        .map__overlay {
            @include transition;
            background: color(base, White);
            display: flex;
            flex-direction: column;

            @include breakpoint(medium) {
                position: absolute;
                left: 2rem;
                top: 2rem;
                bottom: 2rem;
                width: 36rem;
            }

            @include breakpoint(small only) {
                @include border-radius;
                border: .1rem solid color(CustomGray, Gray-200);

                &.--list {
                    max-height: 61rem;
                }
            }

            .--header {
                padding: 2rem;
                border-bottom: .1rem solid color(CustomGray, Gray-200);
                position: relative;
                margin: 0;

                @include breakpoint(medium) {
                    padding: 3rem;
                }

                h2 {
                    margin: 0 0 2rem;
                    font-size: 2.4rem;
                    line-height: 2.6rem;

                    @include breakpoint(medium) {
                        margin: 0 0 3rem;
                        font-size: 3.2rem;
                        line-height: 3.4rem;
                    }
                }

                > button {
                    height: 4.6rem;
                    width: 100%;
                    font-size: 1.4rem;
                    padding: 1rem 2rem 1rem 5rem;
                    display: flex;
                    align-items: center;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1328' data-name='Group 1328' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_204' data-name='Rectangle 204' width='24' height='24' fill='none'/%3E%3Cpath id='Path_3294' data-name='Path 3294' d='M46.2,57.571a.75.75,0,0,0,1.426-.025l2.208-7.177a.75.75,0,0,1,.5-.5l7.177-2.2a.75.75,0,0,0,.025-1.426l-16.5-6.12a.75.75,0,0,0-.952.949Z' transform='translate(-36.29 -36.326)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    background-position: left 1rem center;
                    margin: 0 0 1rem;
                }

                input {
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%0A%3Csvg id='Group_1326' data-name='Group 1326' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_166' data-name='Rectangle 166' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_10' data-name='Ellipse 10' cx='7.5' cy='7.5' r='7.5' transform='translate(3 3)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_79' data-name='Line 79' x2='5.197' y2='5.197' transform='translate(15.803 15.803)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                    background-position: left 1rem center;
                    padding: 1rem 2rem 1rem 5rem;
                }

                .field__holder {
                    > label {
                        padding: 1rem 2rem 1rem 5rem;
                    }

                    &.--placeholder {
                        > input {
                            padding: 1.8rem 2rem .2rem 5rem;
                        }

                        > label {
                            padding: .5rem 2rem .5rem 5rem;
                        }
                    }
                }

                .--error {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: color(base, Red);
                    display: inline-flex;
                    margin: .5rem 0 0;
                }

                .mobile__toggle {
                    @include border-radius;
                    display: flex;
                    margin: 1rem 0 0;
                    background: color(CustomGray, Gray-200);

                    button {
                        @include border-radius;
                        border: .1rem solid color(CustomGray, Gray-200);
                        flex: 1;
                        gap: .8rem;
                        font-size: 1.6rem;
                        line-height: 4.8rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: .5s ease-out;
                        background: linear-gradient(to right, transparent 50%, color(base, White) 50%) left;
                        background-size: 200%;
                        color: color(CustomGray, Gray-400);

                        &::before {
                            content: '';
                            width: 2.4rem;
                            height: 2.4rem;
                        }

                        &:first-child {
                            border-right: none;

                            &::before {
                                background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group'%3E%3Crect id='Rectangle_222' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cline x1='8.25' y1='6' x2='20.25' y2='6' id='Line_95' stroke='%237B7B7B' stroke-linecap='round' stroke-linejoin='round'%3E%3C/line%3E%3Cline x1='8.25' y1='12' x2='20.25' y2='12' id='Line_96' stroke='%237B7B7B' stroke-linecap='round' stroke-linejoin='round'%3E%3C/line%3E%3Cline x1='8.25' y1='18' x2='20.25' y2='18' id='Line_97' stroke='%237B7B7B' stroke-linecap='round' stroke-linejoin='round'%3E%3C/line%3E%3Ccircle id='Ellipse_27' fill='%237B7B7B' fill-rule='nonzero' cx='4.125' cy='6' r='1.125'%3E%3C/circle%3E%3Ccircle id='Ellipse_28' fill='%237B7B7B' fill-rule='nonzero' cx='4.125' cy='12' r='1.125'%3E%3C/circle%3E%3Ccircle id='Ellipse_29' fill='%237B7B7B' fill-rule='nonzero' cx='4.125' cy='18' r='1.125'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                            }
                        }

                        &:last-child {
                            background: linear-gradient(to left, transparent 50%, color(base, White) 50%) right;
                            background-size: 200%;
                            border-left: none;

                            &::before {
                                background-image: url("data:image/svg+xml,%3Csvg id='Group_1389' data-name='Group 1389' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_213' data-name='Rectangle 213' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_25' data-name='Ellipse 25' cx='3' cy='3' r='3' transform='translate(9 6.75)' fill='none' stroke='%237b7b7b' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3304' data-name='Path 3304' d='M63,31.5c0,6.75-7.5,12-7.5,12S48,38.25,48,31.5a7.5,7.5,0,0,1,15,0Z' transform='translate(-43.5 -21.75)' fill='none' stroke='%237b7b7b' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                            }
                        }

                        &.--active {
                            background-position: right bottom;
                            color: color(base, Anthracite);

                            &:first-child::before {
                                background-image: url("data:image/svg+xml,%3Csvg id='Group_1390' data-name='Group 1390' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_222' data-name='Rectangle 222' width='24' height='24' fill='none'/%3E%3Cline id='Line_95' data-name='Line 95' x2='12' transform='translate(8.25 6)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_96' data-name='Line 96' x2='12' transform='translate(8.25 12)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_97' data-name='Line 97' x2='12' transform='translate(8.25 18)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Ccircle id='Ellipse_27' data-name='Ellipse 27' cx='1.125' cy='1.125' r='1.125' transform='translate(3 4.875)' fill='%23212121'/%3E%3Ccircle id='Ellipse_28' data-name='Ellipse 28' cx='1.125' cy='1.125' r='1.125' transform='translate(3 10.875)' fill='%23212121'/%3E%3Ccircle id='Ellipse_29' data-name='Ellipse 29' cx='1.125' cy='1.125' r='1.125' transform='translate(3 16.875)' fill='%23212121'/%3E%3C/svg%3E%0A");
                            }

                            &:last-child {
                                background-position: left bottom;

                                &::before {
                                    background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group'%3E%3Crect id='Rectangle_213' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Ccircle id='Ellipse_25' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' cx='12' cy='9.75' r='3'%3E%3C/circle%3E%3Cpath d='M19.5,9.75 C19.5,16.5 12,21.75 12,21.75 C12,21.75 4.5,16.5 4.5,9.75 C4.5,5.60786438 7.85786438,2.25 12,2.25 C16.1421356,2.25 19.5,5.60786438 19.5,9.75 Z' id='Path_3304' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                }
            }

            .store__list {
                flex: 1;
                overflow-y: auto;
                border-bottom: .1rem solid color(CustomGray, Gray-200);

                li {
                    padding: 2rem 0;
                    position: relative;

                    &:not(:last-child)::after {
                        content: '';
                        display: block;
                        height: .1rem;
                        background: color(CustomGray, Gray-200);
                        position: absolute;
                        left: 3rem;
                        right: 3rem;
                        bottom: 0;
                    }

                    .--item {
                        border-left: .6rem solid transparent;
                        padding: 0 3rem 0 2.4rem;
                        cursor: pointer;

                        h4 {
                            font-size: 2rem;
                            line-height: 2.4rem;
                            font-weight: 500;
                            margin: 0 0 .5rem;
                        }

                        address, address a {
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            color: color(CustomGray, Gray-400);

                            &:is(a) {
                                @include hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .address__container {
                            position: relative;

                            .--overlay {
                                position: relative;

                                > div {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    display: flex;
                                    align-items: center;
                                    padding: 0 2rem;

                                    button {
                                        z-index: 1;
                                        border: .1rem solid color(CustomGray, Gray-400);
                                    }
                                }

                                address {
                                    transition: filter .3s ease-in-out;
                                    filter: blur(.4rem);
                                }

                                &.--active {
                                    address {
                                        filter: blur(0);
                                    }

                                    button {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .--actions {
                            margin: 1rem 0 0;

                            a {
                                font-weight: 500;
                                font-size: 1.6rem;
                                line-height: 1.8rem;
                                text-decoration: underline;
                            }
                        }
                    }

                    &.--active {
                        .--item {
                            border-color: color(base, Blue);
                        }
                    }
                }
            }
        }
    }
}

//.nearest__stores {
//    display: flex;
//    align-items: center;
//    gap: 1rem;
//    margin: 0 0 3rem;
//    flex-wrap: wrap;
//
//    button {
//        background: color(base, LightBlue);
//        border-radius: .5rem;
//        font-size: 1.4rem;
//        font-weight: 500;
//        color: color(base, Primary);
//        padding: .5rem 1rem;
//
//        @include hover {
//            background: color(base, LightBlueHover);
//        }
//
//        &.--active {
//            background: color(base, Secondary);
//            color: color(base, White);
//        }
//
//        small {
//            font-weight: normal;
//        }
//    }
//}
