.product__configuration {
    .product__configurable {
        display: flex;
        flex-flow: column;
        gap: 2rem;
        padding: 2rem 0 0;

        @include breakpoint(medium) {
            padding: 3rem 2rem;
        }

        .--title {
            font-weight: 500;
            font-size: 1.6rem;
            display: block;
            margin: 0 0 1.5rem;

            @include breakpoint(medium) {
                font-size: 2.2rem;
                margin: 0 0 2rem;
            }
        }

        .product__configurable-option {
            .product__configurable-option__select {
                label {
                    font-weight: 500;
                    font-size: 1.4rem;
                    text-transform: capitalize;
                    margin: 0 0 .75rem;
                    display: block;
                }
            }
        }
    }

    .product__add-to-cart-container {
        margin: 4rem 0 0;
    }

    .product__price-container {
        padding: 2rem;
        border-bottom: .1rem solid color(CustomGray, Gray-200);
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        align-items: baseline;

        > span {
            font-size: 2.8rem;
            line-height: 3rem;
            font-weight: 500;

            &.price__old-price {
                font-size: 2rem;
                line-height: 2.2rem;
                color: color(CustomGray, Gray-400);
                text-decoration: line-through;
                font-weight: normal;
            }
        }
    }
}
