.home_body {
    .page-builder__content-block.--first {
        .homepage__hero--block-wrapper {
            .--alternative {
                padding: 14rem 0 0;

                .--background {
                    height: 28rem;
                }
            }
        }
    }
}
