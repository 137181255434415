//.blaze-slider {
//
//    &.start {
//        .blazer-controls {
//            .blaze-prev {
//                opacity: .4;
//                pointer-events: none;
//            }
//        }
//    }
//
//    &.end {
//        .blazer-controls {
//            .blaze-next {
//                opacity: .4;
//                pointer-events: none;
//            }
//        }
//    }
//
//    &.static {
//        .blazer-controls {
//            display: none;
//        }
//    }
//
//    .blazer-controls {
//        display: flex;
//        align-items: center;
//
//        button {
//            width: 2.4rem;
//            height: 2.6rem;
//            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
//
//            &.blaze-prev {
//                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
//            }
//        }
//    }
//}

.blaze-pagination {
    display: flex;
    gap: .8rem;

    button {
        @include transition;
        width: .8rem;
        height: .8rem;
        background: color(CustomGray, Gray-200);
        border-radius: 50%;
        text-indent: -999rem;

        &.active {
            background: color(base, Red);
            width: 1rem;
            height: 1rem;
        }
    }
}
