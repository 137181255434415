section.contact-form {
    ._header {
        margin-bottom: 2rem;
    }

    .required__message {
        margin-top: 1rem;

        p {
            font-size: 1.3rem;
        }
    }

    .field__actions {
        flex: 0 0 100%;
        justify-content: flex-start;

        button {
            flex: none;
            font-size: 1.8rem;
            min-width: 20rem;
        }
    }

    .footer__text {
        margin-top: 2rem;

        p, a {
            font-size: 1.4rem;
            line-height: 2.4rem;

            &:is(a) {
                text-decoration: underline;
            }
        }
    }
}

.form__block {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;

    &.--padding {
        padding: 6rem 0;
    }

    ._header {
        display: none;
    }

    .sub__heading {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 1rem 0 0;

        @include breakpoint(medium) {
            font-size: 2.4rem;
        }
    }

    .global__content {
        margin-top: 3rem;
    }

    .usp__block {
        margin: 2rem 0;

        @include breakpoint(medium) {
            margin: 4rem 0;
        }

        p {
            display: flex;
            gap: 1.6rem;

            &::before {
                content: '';
                display: inline-block;
                width: 1rem;
                flex: 0 0 1rem;
                height: 1rem;
                background: color(base, Green);
                border-radius: 50%;
                margin-top: 0.8rem;
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .image__holder {
        height: 100%;
        position: relative;
        text-align: center;

        > div {
            position: sticky;
            top: 8rem;
            z-index: 1;

            picture {
                display: flex;
                margin: 0 auto;
            }
        }
    }

    section.contact-form {
        margin-top: 3rem;

        .grid-container {
            padding: 0;

            .small-24.medium-12.medium-offset-6 {
                margin-left: 0;
                width: 100%;
            }
        }

        .group__container {
            @include breakpoint(small only) {
                &:not(.--checkbox) {
                    max-width: 80%;
                }
            }

            input[type='checkbox'] {
                border-color: color(Gray, Gray-400);
                background-color: rgba(255, 255, 255, 0.2);

                &:checked {
                    background: color(base, Green);
                    border-color: color(base, Green);
                }
            }

            .field__container {
                input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select, textarea {
                    border-color: color(Gray, Gray-400);
                    background-color: rgba(255, 255, 255, 0.2);
                }

                label:not(.file-upload), span.label {
                    font-size: 1.8rem;
                    display: flex;
                    align-items: center;
                    padding-bottom: 1.6rem;
                    gap: 1rem;

                    @include breakpoint(medium) {
                        font-size: 2.4rem;
                    }

                    &::before {
                        @include transition();
                        content: '';
                        width: 1rem;
                        height: 1rem;
                        background: color(base, Green);
                        border-radius: 50%;
                    }
                }
            }

            .input__checkbox {
                > p {
                    display: none;
                }
            }

            &.--multiple {
                .field__container:not(:first-child) {
                    label {
                        display: none;
                    }
                }
            }
        }
    }

    &.--full-width {
        form {
            flex-wrap: wrap;
            row-gap: 0;

            @include breakpoint(medium) {
                flex-direction: row;
                column-gap: 6rem;
            }

            .group__container {
                flex: 0 0 calc(50% - 3rem);

                &.--checkbox {
                    flex: 0 0 100%;
                }
            }

            .cf-turnstile {
                flex: 0 0 100%;
            }
        }
    }
}
