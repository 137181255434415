@use 'sass:math';

.off-canvas.position-left {
    width: 100%;
    @include breakpoint(small only) {
        transform: translateX(-100%);
    }

    .modal__header {
        display: flex;
        align-items: center;
        height: 6.5rem;
        padding: 0 2rem;

        .--logo {
            max-width: 12.5rem;
        }

        .title {
            font-size: 2rem;
            font-weight: 500;
            color: color(base, Anthracite);
        }

        .close-button {
            position: initial;
            margin-left: auto;
            font-size: 2.8rem;

            > span {
                display: block;
                width: 3.4rem;
                height: 3.4rem;
                color: color(base, White);
                border-radius: 50%;
                background-color: color(base, Anthracite);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_1412' data-name='Group 1412' transform='translate(-0.147 -0.147)'%3E%3Crect id='Rectangle_214' data-name='Rectangle 214' width='24' height='24' transform='translate(0.147 0.147)' fill='none'/%3E%3Cline id='Line_91' data-name='Line 91' x2='16.743' transform='translate(3.776 12.147)' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' opacity='0'/%3E%3Cline id='Line_92' data-name='Line 92' x2='16.743' transform='translate(6.228 6.494) rotate(45)' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_93' data-name='Line 93' x2='16.743' transform='translate(6.228 18.333) rotate(-45)' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

    &:not(.in-canvas-for-medium) {
        background-color: color(base, White);
    }

    &.is-open {
        transform: translateX(0);

        section {
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;

            .modal__content {
                height: 100%;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }
        }
    }

    .vertical.menu.drilldown {
        display: flex;
        flex-direction: column;
        height: calc(100% - 6.5rem);

        nav {
            ul li {
                > a {
                    border: none;
                    font-weight: 500;
                    font-size: 3rem;
                    line-height: 7rem;
                    padding: 0 2rem;
                }

                &.--has-sub {
                    > a {
                        background-repeat: no-repeat;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.8' height='9.814' viewBox='0 0 16.8 9.814'%3E%3Cpath id='Path_3278' data-name='Path 3278' d='M15.74.354,8.047,8.046.354.354' transform='translate(0.353 0.354)' fill='none' stroke='%23212121' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E%0A");
                        background-position: right 2.8rem center;
                    }

                    &.--active {
                        > a {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.8' height='9.814' viewBox='0 0 16.8 9.814'%3E%3Cpath id='Path_3313' data-name='Path 3313' d='M15.74.354,8.047,8.046.354.354' transform='translate(16.447 9.461) rotate(180)' fill='none' stroke='%23212121' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E%0A");
                        }

                        ul {
                            display: block;
                        }
                    }
                }

                ul {
                    display: none;

                    li {
                        padding: 1rem 0;
                        > a {
                            display: flex;
                            gap: 2rem;
                            font-size: 2rem;
                            line-height: 2.4rem;
                            align-items: center;

                            img {
                                aspect-ratio: 20/17;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        .mobile__navigation--bottom {
            border-top: .1rem solid color(CustomGray, Gray-200);
            margin-top: auto;
            padding: 3rem 2rem;

            > a {
                font-size: 2rem;
                font-weight: 500;
                padding-left: 4rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_1445' data-name='Group 1445' transform='translate(11698 17818)'%3E%3Crect id='Rectangle_223' data-name='Rectangle 223' width='24' height='24' transform='translate(-11698 -17818)' fill='none'/%3E%3Cellipse id='Ellipse_31' data-name='Ellipse 31' cx='4.794' cy='4.794' rx='4.794' ry='4.794' transform='translate(-11690.794 -17814.91)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3306' data-name='Path 3306' d='M.5,5.562C.5,2.118,3.318.5,6.762.5h3.949c3.444,0,6.262,1.618,6.262,5.062' transform='translate(-11694.736 -17802.652)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: left center;
                margin: 0 0 3rem;
                line-height: normal;
            }

            .language__switcher--mobile {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                background-repeat: no-repeat;
                background-position: left center;
                background-image: url("data:image/svg+xml,%3Csvg id='Group_1444' data-name='Group 1444' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_164' data-name='Rectangle 164' width='24' height='24' fill='none'/%3E%3Ccircle id='Ellipse_7' data-name='Ellipse 7' cx='9' cy='9' r='9' transform='translate(3 3)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3275' data-name='Path 3275' d='M88,41a12.9,12.9,0,0,0,3.214,8.765.728.728,0,0,0,1.072,0A12.9,12.9,0,0,0,95.5,41a12.9,12.9,0,0,0-3.214-8.765.728.728,0,0,0-1.073,0A12.9,12.9,0,0,0,88,41Z' transform='translate(-79.75 -28.999)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cline id='Line_78' data-name='Line 78' x2='18' transform='translate(3 12)' fill='none' stroke='%23212121' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
                padding-left: 4rem;

                > span, > a {
                    font-size: 2rem;
                    color: color(CustomGray, Gray-400);
                    font-weight: 500;
                    line-height: 1;
                    text-transform: uppercase;

                    &:is(span) {
                        color: color(base, Anthracite);
                    }
                }
            }
        }
    }
}

.main__navigation {
    > ul {
        display: flex;
        gap: 4rem;

        > li {
            > a, > span {
                @include transition;
                font-size: 1.6rem;
                font-weight: 500;
                position: relative;
                display: flex;
                align-items: center;
                gap: 1rem;
                line-height: 6.4rem;

                @media screen and (max-width: 950px) {
                    font-size: 1.2rem;
                }

                &::before {
                    @include transition;
                    content: '';
                    height: .2rem;
                    width: 0;
                    background: color(base, Anthracite);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 1.6rem;
                }
            }

            @include hover {
                > a {
                    &::before {
                        width: 100%;
                    }
                }
            }

            &.--has-children {
                > a {
                    &::after {
                        content: '';
                        display: block;
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='4px' viewBox='0 0 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' stroke='%23212121'%3E%3Cpolyline id='Path_3277' points='8 -1.11022302e-16 4 4 0 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                        width: .8rem;
                        height: .4rem;
                    }
                }
            }

            .--dropdown {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                background-color: color(base, White);
                padding: 4rem 0;
                border-top: .1rem solid color(CustomGray, Gray-150);

                .align-flex-end {
                    display: flex;
                    align-items: flex-end;
                }

                .navigation__content {
                    h4 {
                        font-size: 2rem;
                        line-height: 2.4rem;
                        font-weight: 500;
                        margin: 0 0 2rem;
                    }
                }

                .dropdown__items {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 2rem;

                    a {
                        display: flex;
                        align-items: center;
                        gap: 2rem;

                        img {
                            aspect-ratio: 1;
                            object-fit: cover;
                            flex: 0 0 10rem;
                        }

                        span {
                            font-size: 2rem;
                            line-height: 2.4rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
