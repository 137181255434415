.homepage__four-column--block-wrapper {
    .homepage__four-column--block {
        display: grid;
        grid-gap: .4rem;
        grid-template-columns: repeat(4, 1fr);

        @media screen and (max-width: 880px) {
            grid-template-columns: repeat(2, 1fr);

            @include breakpoint(small only) {
                grid-gap: 0;
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .--block {
            position: relative;

            @include breakpoint(small only) {
                margin: 0 0 2rem;
            }

            .--count {
                position: absolute;
                font-size: 1.4rem;
                line-height: 1.8rem;
                font-weight: 500;
                top: 2rem;
                left: 2rem;
                z-index: 2;
            }

            > span, > a {
                display: flex;

                img {
                    @include transition;
                    width: 100%;
                    height: auto;

                    @include breakpoint(small only) {
                        aspect-ratio: 1/1;
                        object-fit: cover;
                    }

                    &.--hover {
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }

                .--title {
                    @include transition;
                    display: flex;
                    position: absolute;
                    bottom: 2rem;
                    left: 2rem;
                    right: 2rem;
                    font-size: 2rem;
                    line-height: 2.4rem;
                    max-width: 70%;
                    font-weight: 500;
                    text-shadow: color(base, White) .1rem .1rem .3rem;

                    > span {
                        @include transition;
                        display: block;
                        height: 0;
                        width: 0;
                        flex: 0;
                        background: color(base, Anthracite);
                        border-radius: 50%;
                        margin-top: .8rem;
                    }
                }

                @include hover {
                    &:is(a) {
                        img.--hover {
                            opacity: 1;
                        }

                        .--title {
                            max-width: calc(70% + 1rem);

                            > span {
                                height: .8rem;
                                width: .8rem;
                                flex: 0 0 .8rem;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
