.catalog__category-filter {

    @include breakpoint(medium) {
        max-width: 85%;
    }

    .modal__content {
        .--sub-categories {
            dd {
                ol li {
                    a {
                        font-size: 1.5rem;
                        line-height: 2.7rem;
                        @include hover {
                            text-decoration: underline;
                        }
                    }

                    strong {
                        font-size: 1.5rem;
                        line-height: 2.7rem;
                        font-weight: 500;
                        text-decoration: underline;
                    }
                }
            }
        }

        dl {
            border-bottom: .1rem solid color(CustomGray, Gray-200);
            padding: .5rem 0;

            dt {
                font-size: 1.6rem;
                line-height: 2rem;
                padding: .8rem 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                background-size: 1.4rem;
                background-repeat: no-repeat;
                background-position: right center;
                cursor: pointer;
                font-weight: 500;

                &.--visible {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                }

                @include breakpoint(small only) {
                    padding: 0 1.5rem;
                    background-position: right 2.4rem center;
                    font-size: 1.8rem;
                    line-height: 4.2rem;
                    background-size: 2rem;
                }
            }

            dd {
                padding-bottom: .5rem;
                @include breakpoint(small only) {
                    padding: 1.5rem;
                }

                ol {
                    li label {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        gap: 1rem;

                        .--checkbox {
                            border-radius: .4rem;
                            background: color(base, White);
                            width: 2rem;
                            height: 2rem;
                            border: 0.1rem solid color(CustomGray, Gray-300);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .--title {
                            flex: 1;
                            font-size: 1.4rem;
                            line-height: 3rem;
                            color: color(gray, Gray-600);

                            @include breakpoint(small only) {
                                font-size: 1.6rem;
                                line-height: 3.4rem;
                            }
                        }

                        .--count {
                            display: none;
                        }

                        &.--active {
                            .--checkbox {
                                border-radius: .4rem;
                                background: color(base, Green);
                                border-color: color(base, Green);
                                box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);

                                &::after {
                                    content: '';
                                    display: block;
                                    width: 1.2rem;
                                    height: 1.2rem;
                                    background-size: contain;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1588' data-name='Group 1588' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                                }
                            }
                        }

                        @include hover() {
                            .--checkbox {
                                border-color: color(CustomGray, Gray-400);
                            }
                        }
                    }

                    &.--swatch {
                        display: grid;
                        grid-template-columns: repeat(6, 1fr);
                        gap: 1rem;

                        li {
                            label {
                                width: 100%;
                                aspect-ratio: 1;
                                border-radius: 50%;
                                border: .1rem solid color(CustomGray, Gray-300);

                                &.--active {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1588' data-name='Group 1588' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 65%;
                                    border-color: color(base, Green);
                                    box-shadow: 0 0 20px rgba(0, 215, 94, 0.3);

                                    &.--light {
                                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_1588' data-name='Group 1588' transform='translate(-0.409 -0.412)'%3E%3Crect id='Rectangle_229' data-name='Rectangle 229' width='18' height='18' transform='translate(0.409 0.412)' fill='none'/%3E%3Cpath id='Path_3310' data-name='Path 3310' d='M40,77.226l4.064,4.064L53.355,72' transform='translate(-36.978 -66.656)' fill='none' stroke='#{color(base, Anthracite)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
                                    }
                                }
                            }
                        }
                    }
                }

                .price__filter {
                    padding: 1rem 0 0;

                    .vue-slider {
                        margin: 0 .7rem;

                        .vue-slider-rail {
                            background: color(CustomGray, Gray-150);
                            border-radius: 0;

                            .vue-slider-process {
                                background: color(base, Anthracite);
                            }

                            .vue-slider-dot {
                                .vue-slider-dot-handle {
                                    box-shadow: none;
                                    background: color(base, Anthracite);
                                }
                            }
                        }

                        .vue-slider-dot-tooltip-inner {
                            background: color(base, Anthracite);
                            border-color: color(base, Anthracite);

                            .vue-slider-dot-tooltip-text {
                                color: color(base, White);
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .custom__controls {
                        margin: 2rem 0 1rem;
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        justify-content: space-between;

                        input[type='number'] {
                            width: 35%;
                            @include breakpoint(medium only) {
                                padding: 0 1rem;
                            }
                        }
                    }
                }

                > button {
                    margin: 1rem 0 0;
                    font-weight: 500;
                    font-size: 1.3rem;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .modal__footer {
        padding: 1rem 1.5rem;
        border-top: .1rem solid color(CustomGray, Gray-200);
        display: flex;
        gap: 2rem;
        background: color(base, White);

        button {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: color(base, White);
                font-size: 1.4rem;
            }
        }
    }
}

@include breakpoint(small only) {
    #offCanvas-filter {
        .modal__header {
            display: flex;
            border-bottom: .1rem solid color(CustomGray, Gray-200);

            button {
                margin-left: auto;

                span {
                    display: block;
                    color: color(base, White);
                    font-size: 3.4rem;
                }
            }
        }
    }
}
