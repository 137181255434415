.breadcrumbs {
    overflow: hidden;
    padding: 1rem 0;
    @include breakpoint(medium) {
        padding: 2.5rem 0;
    }

    ol {
        display: flex;
        align-items: center;

        li {
            display: flex;
            align-items: center;

            a, span {
                display: block;
                font-size: 1.4rem;
                white-space: nowrap;
                color: color(CustomGray, Gray-400);
                line-height: 3rem;
            }

            a:hover {
                text-decoration: underline;
            }

            .--separator {
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(CustomGray, Gray-400)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                margin: 0 .3rem;
                width: 1.4rem;
                height: 3rem;
                background-size: 1.2rem;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}
