.homepage__three-column--block-wrapper {
    .homepage__three-column--block {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        @include breakpoint(medium) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: .5rem;
        }

        .--block {
            @include breakpoint(small only) {
                margin: 0 0 4rem;
            }

            .--content {
                position: relative;
                display: flex;

                @include breakpoint(small only) {
                    aspect-ratio: 59/48;
                    > img {
                        aspect-ratio: 59/48;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    aspect-ratio: 433/496;
                }

                .--overlay {
                    @include transition;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 0;

                    @include breakpoint(small only) {
                        height: 100%;
                    }

                    a {
                        display: none;
                        @include breakpoint(small only) {
                            display: block;
                            background-color: rgba(0,0,0,0.2);
                        }
                    }
                }

                @include hover {
                    .--overlay {
                        background: color(base, AnthraciteOverlay);
                        bottom: 0;
                        height: 100%;

                        a {
                            display: block;
                        }
                    }
                }
            }
        }

        p {
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin: 1.5rem 0 0;
            padding-right: 4rem;

            @include breakpoint(medium) {
                font-size: 2rem;
                line-height: 2.4rem;
            }
        }
    }
}
