.pagination {
    flex: 1;
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        gap: 1rem;

        li {
            a, span {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: color(CustomGray, Gray-150);
                line-height: 3rem;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                font-size: 1.4rem;
                background-size: 1.8rem;
                background-repeat: no-repeat;
                background-position: center;

                @include hover {
                    background-color: color(CustomGray, Gray-200);
                }

                &.--current {
                    background: color(base, Anthracite);
                    color: color(base, White);
                }

                &.--prev {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
                }
                &.--next {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}
