.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: rgba(0, 0, 0, .85);
        color: color(base, White);
        border-radius: .5rem;
        padding: 1rem 1.5rem 1.5rem;
        font-size: 1.3rem;
        line-height: 1.9rem;
        font-weight: 500;
        max-width: 22rem;
        a {
            font-size: 1.3rem;
            line-height: 1.9rem;
            font-weight: 500;
            color: color(base, White);
            text-decoration: underline;
        }
        strong {
            color: color(base, White);
        }

        @include breakpoint(small only) {
            max-width: 80vw;
            max-height: 50vh;
            overflow-y: auto;
            scroll-behavior: smooth;
        }
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: .5rem;
        border-color: rgba(0, 0, 0, .85);
    }
    &-wrapper {
        display: inline-block;
    }
    &[x-placement^="top"] {
        margin-bottom: .5rem;

        .tooltip-arrow {
            border-width: .5rem .5rem 0 .5rem;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -.5rem;
            left: calc(50% - .5rem);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: .5rem;

        .tooltip-arrow {
            border-width: 0 .5rem .5rem .5rem;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -.5rem;
            left: calc(50% - .5rem);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: .8rem;

        .tooltip-arrow {
            border-width: .5rem .5rem .5rem 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -.5rem;
            top: 50%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: .5rem;

        .tooltip-arrow {
            border-width: .5rem 0 .5rem .5rem;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -.5rem;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}
