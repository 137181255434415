.customer-account {
    @include breakpoint(medium) {
        margin-top: 4rem;
    }

    .customer__account-navigation_toggle {
        margin: 0 0 2rem;
    }

    .customer__account-navigation {
        nav {
            margin: 2rem 0;

            ul li {
                padding: .5rem 0;

                @include breakpoint(small only) {
                    padding: 1rem;
                }

                a {
                    line-height: 2.8rem;
                    font-size: 1.5rem;
                    border-left: .3rem solid transparent;
                    padding: 0 1rem;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    @include breakpoint(small only) {
                        font-size: 2rem;
                    }

                    span {
                        display: block;
                        width: 2.2rem;
                        height: 2.2rem;

                        &.--overview {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' /%3E%3C/svg%3E%0A");
                        }

                        &.--settings {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E%0A");
                        }

                        &.--addresses {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z' /%3E%3C/svg%3E%0A");
                        }

                        &.--orders {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                        }

                        &.--newsletter {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                        }

                        &.--logout {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Anthracite)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75' /%3E%3C/svg%3E%0A");
                        }
                    }

                    @include hover {
                        text-decoration: underline;
                    }
                }

                &.--active {
                    a {
                        border-color: color(base, Anthracite);
                    }
                }
            }
        }
    }

    .title__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > a {
            font-size: 1.5rem;
            font-weight: 500;
            display: inline-flex;
            margin: 1rem 0 0;

            @include breakpoint(medium) {
                margin: 0 0 0 auto;
            }

            @include hover {
                text-decoration: underline;
            }
        }
    }

    h1 {
        font-size: 2.8rem;
        line-height: 3.2rem;
        font-weight: 500;

        &.--center {
            text-align: center;
        }
    }

    h2 {
        font-size: 2.4rem;
        font-weight: 500;

        &.--center {
            text-align: center;
        }
    }

    h3 {
        font-size: 1.8rem;
        font-weight: 500;

        &.--center {
            text-align: center;
        }

        &.--margin-bottom {
            margin-bottom: 2rem;
        }
    }

    form {
        > h3 {
            margin: 2rem 0;
        }
    }

    .--intro-block {
        @include border-radius;
        background-color: color(CustomGray, Gray-150-85);
        padding: 2rem 3rem;
        margin: 0 0 3rem;

        @include breakpoint(medium) {
            max-width: 60%;
            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Group_1411' data-name='Group 1411' transform='translate(11698 17818)'%3E%3Crect id='Rectangle_223' data-name='Rectangle 223' width='24' height='24' transform='translate(-11698 -17818)' fill='none'/%3E%3Cellipse id='Ellipse_31' data-name='Ellipse 31' cx='4.794' cy='4.794' rx='4.794' ry='4.794' transform='translate(-11690.794 -17814.91)' fill='none' stroke='#{color(CustomGray, Gray-300)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_3306' data-name='Path 3306' d='M.5,5.562C.5,2.118,3.318.5,6.762.5h3.949c3.444,0,6.262,1.618,6.262,5.062' transform='translate(-11694.736 -17802.652)' fill='none' stroke='#{color(CustomGray, Gray-300)}' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: right 2rem center;
            background-size: 18%;
            padding: 2rem 15rem 2rem 3rem;
        }

        h1 {
            font-weight: 500;
            font-size: 2.4rem;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 3.2rem;
            }
        }

        p {
            font-size: 1.4rem;
            line-height: 2.4rem;
            opacity: .8;
            margin: 1rem 0 0;

            @include breakpoint(medium) {
                font-size: 1.6rem;
            }
        }
    }

    .last__order, .order__row {
        margin: 0 0 3rem;
        padding: 0 0 3rem;
        border-bottom: .1rem solid color(CustomGray, Gray-200);

        .--header {
            display: flex;
            gap: 1rem;
            align-items: center;
            flex-wrap: wrap;

            span, small {
                font-size: 1.4rem;
                font-weight: 500;
                color: color(base, Anthracite);
            }
        }

        .item__list {
            display: grid;
            gap: 1rem;
            margin: 1.5rem 0 0;
            grid-template-columns: repeat(2, 1fr);

            @include breakpoint(medium) {
                grid-template-columns: repeat(4, 1fr);
            }

            a {
                @include transition;
                @include border-radius;
                display: flex;
                flex-direction: column;
                padding: 1rem 1.5rem;
                box-shadow: rgba(9, 30, 66, 0.25) 0 4px 8px -2px, rgba(9, 30, 66, 0.08) 0 0 0 1px;

                @include hover {
                    background-color: color(gray, Gray-100);
                }

                img {
                    object-fit: scale-down;
                    width: 100%;
                    height: 100%;
                }

                h3 {
                    font-weight: 500;
                    margin: 1rem 0 0;
                    font-size: 1.7rem;
                }

                p:last-child {
                    margin: 1rem 0 0;
                    font-weight: 500;

                    &.--default {
                        color: color(alert, Default);
                    }

                    &.--error {
                        color: color(alert, Error);
                    }

                    &.--success {
                        color: color(alert, Success);
                    }
                }
            }
        }

        > a {
            margin: 2rem 0 0;
            font-size: 1.5rem;
            font-weight: 500;
            display: inline-flex;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    .recently-viewed__products {
        margin: 0 0 3rem;
        border-bottom: .1rem solid color(CustomGray, Gray-200);

        .--header {
            margin: 0 0 1.5rem;
            display: flex;
            align-items: center;
            gap: 2rem;

            .blazer-controls {
                margin-left: auto;
            }
        }


        .catalog__category-list .product__tile {
            margin-bottom: 1.5rem;
        }
    }

    .personal__details {
        h2 {
            margin: 0 0 1.5rem;
        }

        .personal__details-container {
            @include border-radius;
            width: 100%;
            padding: 1rem 2rem;
            border: .1rem solid color(CustomGray, Gray-200);
            margin: 0 0 2rem;

            @include breakpoint(medium) {
                min-width: 65%;
                width: fit-content;
                margin: 0;
            }

            .--content {
                &:not(:last-child) {
                    margin: 0 0 1rem;
                }

                p, a {
                    line-height: 2.6rem;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    .customer__account_block {
        margin: 2rem 0;

        &.--background {
            @include border-radius;
            background: color(gray, Gray-100);
            padding: 2rem;
        }
    }

    .customer-account-container {
        margin: 2rem 0;
    }

    .dashboard__addresses {
        h2 {
            margin: 0 0 1.5rem;
        }

        .dashboard__addresses-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
        }

        > a {
            margin: 1rem 0 0;
            font-size: 1.5rem;
            font-weight: 500;
            display: inline-flex;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    .address-container {
        margin: 2rem 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        @include breakpoint(medium) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .address__block {
        @include border-radius;
        border: .1rem solid color(CustomGray, Gray-200);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;

        @include breakpoint(medium) {
            padding: 1.5rem 2rem;
        }

        > a {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 1.5rem;
            text-align: center;

            svg {
                width: 35%;
            }

            @include hover {
                text-decoration: underline;
                color: color(base, Anthracite);

                svg {
                    stroke: color(base, Anthracite);
                }
            }
        }

        h3 {
            color: color(alert, Success);
            font-size: 1.4rem;
            margin: 0 0 1rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(alert, Success)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
            background-size: 1.6rem;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 2.5rem;
        }

        p {
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0 0 1rem;

            @include breakpoint(medium) {
                font-size: 1.9rem;
            }
        }

        address {
            color: color(gray, Gray-700);
            font-size: 1.5rem;
            line-height: 2.5rem;
        }

        .--actions {
            display: flex;
            gap: 1rem;
            margin: 2rem 0 0;

            a {
                display: flex;
                height: 4rem;

                @include breakpoint(small only) {
                    text-indent: -999rem;
                    flex: 1;
                }

                @include breakpoint(medium) {
                    gap: 1rem;

                    &:last-child {
                        flex: 1;
                    }

                    &:first-child {
                        width: 4rem;
                        height: 4rem;
                        padding: 0;
                    }
                }

                svg {
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }
    }

    .newsletter__content {
        margin: 3rem 0;
    }

    .order__row {
        margin: 3rem 0;

        .--header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include breakpoint(medium) {
                gap: 1rem;
            }

            > p {
                display: flex;
                align-items: center;
                gap: 1rem;
                line-height: normal;

                time, span {
                    color: color(gray, Gray-900);
                    font-weight: 500;
                }
            }
        }

        .--actions {
            display: flex;
            gap: 3rem;
            margin: 3rem 0 0;

            a {
                font-size: 1.5rem;
                font-weight: 500;
                display: inline-flex;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .order__totals-container {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;

        .order__totals {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 1.5rem 0;
                font-size: 1.6rem;
                gap: 6rem;

                &:last-child {
                    margin: 2rem 0 0;
                    padding: 2rem 0 0;
                    border-top: .1rem solid color(gray, Gray-300);

                    span {
                        font-weight: 500;
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
