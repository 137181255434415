.homepage__hero--block-wrapper {
    position: relative;

    picture {
        display: flex;

        img {
            width: 100%;
            height: auto;

            @include breakpoint(medium) {
                max-height: 83.5rem;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .--content {
        position: absolute;
        top: 6rem;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;

        @include breakpoint(medium) {
            align-items: center;
            top: 0;
        }

        .grid-container {
            width: 100%;

            .inner-content {
                padding: 1rem 0 3rem;

                @include breakpoint(medium) {
                    max-width: 45%;
                    padding: 3rem 0;
                }

                @include breakpoint(small only) {
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                }

                h2 {
                    font-size: 3.2rem;
                    line-height: 3.4rem;
                    margin: 0 0 1rem;

                    @include breakpoint(medium) {
                        font-size: 6.4rem;
                        line-height: 6.6rem;
                        margin: 0 0 4rem;
                    }

                    @include tablet-breakpoint() {
                        font-size: clamp(3.2rem, 5vw, 5rem);
                        line-height: clamp(3.4rem, 5vw, 5.2rem);
                    }
                }

                p {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                }

                img {
                    margin: 1rem 0 0;
                }
            }
        }
    }

    .--actions {
        display: flex;
        gap: 1.6rem;
        margin: 4rem 0 0;

        @include breakpoint(small only) {
            margin: auto 0 0;
            flex-direction: column;
            gap: 2rem;
            padding: 2rem 0 0;
        }
    }

    .--alternative {
        position: relative;
        padding: 4rem 0 0;

        .--background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 18rem;
            z-index: -1;
        }

        .alternative__content {
            display: flex;
            flex-direction: column;
            gap: 4rem;
            height: 100%;

            @include breakpoint(small only) {
                margin: 0 0 4rem;
            }

            h2 {
                flex: 0 0 14rem;
                display: flex;
                align-items: flex-end;
                font-size: clamp(4.2rem, 5vw, 5rem);
                line-height: clamp(4.4rem, 5vw, 5.4rem);
                padding: 0 0 2rem;
            }
        }

        .--actions {
            margin: 0;
        }
    }

    .code__block {
        background: #dfedf1;
        border: .1rem solid #d5e6eb;
        padding: 2rem 3rem;
        border-radius: 1rem;
        margin: 2rem 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0 1rem 1.5rem -.3rem, rgba(0, 0, 0, 0.05) 0 .4rem .6rem -.2rem;

        @include breakpoint(medium) {
            max-width: 55%;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            input {
                @include border-radius();
                border: 0;
                background: color(base, White);
                text-transform: uppercase;
                font-weight: 700;
                font-size: 3.4rem;
                padding: .5rem 2rem;
                letter-spacing: .5rem;
                color: #063445;
                height: auto;
                text-align: center;

                &::placeholder {
                    color: color(CustomGray, Gray-200);
                    opacity: 1;
                }

                &::-ms-input-placeholder {
                    color: color(CustomGray, Gray-200);
                }
            }

            p.validator-err {
                color: color(alert, Error);
                font-size: 1.2rem !important;
                margin: .5rem 0 0;
            }

            button {
                margin: 2rem 0 0;
            }
        }
    }
}
